import { useEffect, useState } from "react";
import {
	getAuth,
	signOut,
	onAuthStateChanged,
	signInWithEmailAndPassword,
	getIdToken,
} from "firebase/auth";
import initializeAuth from "./firebase.init";
import Swal from "sweetalert2";

initializeAuth();

const useFirebase = () => {
	const [user, setUser] = useState({});
	const [error, setError] = useState("");
	const [isLoading, setIsloading] = useState(true);
	const [token, setToken] = useState("");
	const auth = getAuth();

	const signInWithEmailPassword = (
		auth,
		email,
		password,
		navigate,
		location,
	) => {
		setIsloading(true);
		signInWithEmailAndPassword(auth, email, password)
			.then(() => {
				Swal.fire("Success!", "SignIn Successful.", "success");
				const destination = location?.state?.from || "/";
				navigate(destination);
			})
			.catch((error) => {
				const errorMessage = error.message;
				setError(errorMessage);
				Swal.fire("Error!", `${errorMessage}`, "error");
			})
			.finally(() => setIsloading(false));
	};

	const logOut = () => {
		setIsloading(true);
		signOut(auth)
			.then(() => {
				setUser({});
			})
			.catch((error) => {})
			.finally(() => setIsloading(false));
	};
	useEffect(() => {
		const unSubscribed = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUser(user);
				setError("");
				getIdToken(user).then((idToken) => setToken(idToken));
			} else {
				setUser({});
			}
			setIsloading(false);
		});
		return () => unSubscribed;
	}, [auth]);
	return {
		auth,
		user,
		error,
		signInWithEmailPassword,
		logOut,
		isLoading,
		token,
	};
};

export default useFirebase;
