import { Box, Container, Divider, Fab, Typography } from "@mui/material";
import React, { useEffect } from "react";
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./Gallery.css";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { Link } from "react-router-dom";
SwiperCore.use([EffectCoverflow, Pagination]);

const HeartOnHeart = () => {
	const [data, setData] = React.useState([]);
	useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/gallery2`)
			.then((res) => res.json())
			.then((data) => setData(data));
	}, []);
	return (
		<Container sx={{ pt: 5 }}>
			<Typography
				className='textColor'
				sx={{ mb: 3, fontWeight: 900 }}
				variant='h4'
				component='div'
				gutterBottom>
				مشروع القلب على القلب
				<Divider className='hr' />
			</Typography>
			<Swiper
				effect={"coverflow"}
				grabCursor={true}
				centeredSlides={true}
				slidesPerView={"auto"}
				initialSlide={1}
				coverflowEffect={{
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier: 1,
					slideShadows: false,
				}}
				pagination={true}
				className='mySwiper'>
				{data?.map((a) => (
					<SwiperSlide key={a?._id}>
						<img src={a?.imageLink2} alt='' />
					</SwiperSlide>
				))}
			</Swiper>{" "}
			<Typography
				className='textColor'
				sx={{ mb: 2, fontWeight: 900, mt: 5 }}
				variant='h6'
				component='div'
				gutterBottom>
				مشروع القلب عالقلب: هو بطاقة تعطى لكل عائلة لمدة ٣ شهور حيث تتمكن
				العائلة باختيار احتياجاتها مرة كل شهر حسب النقط المحددة لها من محل مخصص
				للجمعية و لتخفيف على العائلات صار عنا فرعين: الأول : السفيرة، محلات
				المرحوم ابو عصام حسون التاني: في ابي سمراءحي النزهة شارع الإصلاح_ بناية
				الشيخ عبدالواحد حسون( محل ام العبد) لإستلام الحصص عبر النقاط العائلات
				المكفولة : ١٣٥ عائلة تتغير حسب الحاجة كل ٣ شهور. المطلوب اخراج قيد عائلي
				وتعبئة الطلب عبر الضغط على الزر في أسفل الصفحة. أن يكون المسجل لا يحمل
				بطاقة الشؤون ملاحظة : لو تسجلتو ممكن تاخدو البطاقة هالشهر او خلال ٣ شهور
				و ذلك لكثرة المسجلين عم نحاول قد ما فينا نوصل للجميع و لكن عندنا عدد
				محدد. ( نرجو التفاهم ) اذا حدا حابب يقدم مساعدات لنزيد عدد العيل ساعتها
				منزيد. شكرا للجميع .
			</Typography>
			<Box sx={{ my: 3 }}>
				<Link to='/form2' style={{ textDecoration: "none" }}>
					<Fab
						className='pulse'
						variant='extended'
						sx={{
							px: 4,
							mx: 0.5,
							fontWeight: "bold",
						}}>
						<VolunteerActivismIcon sx={{ mr: 1.5 }} />
						إذا انتم بحاجة غذائية إضغط هنا
					</Fab>
				</Link>
			</Box>
		</Container>
	);
};

export default HeartOnHeart;
