import React from "react";
import Typography from "@mui/material/Typography";
import { Divider, Fab, Grid } from "@mui/material";
import ShopIcon from "@mui/icons-material/Shop";
import AppleIcon from "@mui/icons-material/Apple";
import { Box } from "@mui/system";

const CopyRight = () => {
	return (
		<div>
			<Divider />
			<Typography
				variant='h6'
				gutterBottom
				component='div'
				className='textColor'
				sx={{ mt: 1 }}>
				Copyright &copy;2022 Muslim Way
			</Typography>
			<Grid
				container
				spacing={2}
				className='icons'
				justifyContent='center'
				sx={{ mt: 1.1 }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						mx: 2,
					}}>
					<Fab
						onClick={() =>
							window.open(
								`https://play.google.com/store/apps/details?id=com.logics.async.digital.masbaha`,
								"_blank",
							)
						}
						color='primary'
						aria-label='add'>
						<ShopIcon fontSize='medium' />
					</Fab>
					<Typography
						className='textColor'
						sx={{ mt: 1, fontWeight: "bold" }}
						variant='button'
						display='block'
						gutterBottom>
						Play Store
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						mx: 2,
					}}>
					<Fab
						onClick={() =>
							window.open(
								`https://apps.apple.com/kz/app/muslim-way/id914430321`,
								"_blank",
							)
						}
						color='primary'
						aria-label='add'>
						<AppleIcon fontSize='medium' />
					</Fab>
					<Typography
						className='textColor'
						sx={{ mt: 1, fontWeight: "bold" }}
						variant='button'
						display='block'
						gutterBottom>
						App Store
					</Typography>
				</Box>
			</Grid>
		</div>
	);
};

export default CopyRight;
