import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
	Backdrop,
	Box,
	IconButton,
	Button,
	CircularProgress,
	Container,
	Divider,
	FormControl,
	Select,
	MenuItem,
	FormHelperText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";

import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const Course = () => {
	const navigate = useNavigate();
	const form = useRef();
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, reset } = useForm();
	const [location, setLocation] = React.useState("");

	const handleChange = (event) => {
		setLocation(event.target.value);
	};
	const onSubmit = ({
		a,
		b,
		c,
		d,
		e,
		f,
		g,
		h,
		i,
		j,
		k,
		l,
		m,
		n,
		o,
		locations,
	}) => {
		setSubmitting(true);
		const infos = {
			a,
			b,
			c,
			d,
			e,
			f,
			g,
			h,
			i,
			j,
			k,
			l,
			m,
			n,
			o,
			locations: location,
			imageLink1: imageLink1,
			imageLink2: imageLink2,
			importance: "N/A",
		};
		emailjs
			.sendForm(
				"service_bp2h35d",
				"template_ggd2f99",
				form.current,
				"user_i5224CgRgcclb82ilRvnO",
			)
			.then(
				(result) => {
					axios
						.post(
							`https://sawa4leb.bcard.a2hosted.com/courses`,
							infos,
						)
						.then(function (response) {
							setSubmitting(false);
							Swal.fire({
								icon: "success",
								title: "شكرا لك وسيتم إعادة توجيهك إلى الصفحة الرئيسية",
								showConfirmButton: true,
								timer: 2500,
							}).then(function () {
								reset();
								navigate("/");
							});
						})
						.catch(function (error) {
							console.log(error);
						});
				},
				(error) => {
					console.log(error.text);
				},
			);
	};
	const [imageLink1, setImageLink1] = useState(null);
	const [imageLink2, setImageLink2] = useState(null);
	const [loading1, setLoading1] = useState(false);
	const [loading2, setLoading2] = useState(false);

	const uploadImage1 = async (e) => {
		const files1 = e.target.files;
		const data1 = new FormData();
		console.log("1", data1);
		data1.append("file", files1[0]);
		data1.append("upload_preset", "courses");
		setLoading1(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/dqsndznxf/image/upload",
			{
				method: "POST",
				body: data1,
			},
		);
		const file1 = await res.json();
		setImageLink1(file1.secure_url);
		setLoading1(false);
	};
	const uploadImage2 = async (e) => {
		const files2 = e.target.files;
		const data2 = new FormData();
		console.log("2", data2);
		data2.append("file", files2[0]);
		data2.append("upload_preset", "courses");
		setLoading2(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/dqsndznxf/image/upload",
			{
				method: "POST",
				body: data2,
			},
		);
		const file2 = await res.json();
		setImageLink2(file2.secure_url);
		setLoading2(false);
	};
	const Input = styled("input")({
		display: "none",
	});

	return (
		<Container sx={{ pt: 1 }} dir='rtl'>
			<Typography
				variant='h3'
				className='textColor'
				sx={{ fontWeight: "bold", mb: 0.7 }}>
				طلب  تسجيل  طالب في الدورة العلمية
				{/* Request to register a student in the scientific course
				 */}
			</Typography>
			<Divider className='hr' />
			<Grid
				container
				spacing={0}
				direction='column'
				alignItems='center'
				justifyContent='center'
				style={{ minHeight: "70vh" }}>
				<Box>
					<Grid item md={12} xs={12}>
						<FormControl sx={{ m: 1, minWidth: 120 }}>
							<Select value={location} onChange={handleChange} displayEmpty>
								<MenuItem value=''>
									<em>اختر الموقع</em>
								</MenuItem>
								<MenuItem
									sx={{ my: 0.4, textAlign: "center" }}
									value='ابن هيثم'>
									ابن هيثم
								</MenuItem>
								<MenuItem
									sx={{ my: 0.4, textAlign: "center" }}
									value='السفيرة قرب الجامع'>
									السفيرة قرب الجامع
								</MenuItem>
								<MenuItem
									sx={{ my: 0.4, textAlign: "center" }}
									value='الشوك، حي النزهة'>
									الشوك، حي النزهة
								</MenuItem>
							</Select>
							<FormHelperText>الرجاء إختيار الموقع الأقرب إليكم</FormHelperText>
						</FormControl>
					</Grid>
					<form ref={form} onSubmit={handleSubmit(onSubmit)} method='post'>
						{location && (
							<Box
								display='flex'
								flexDirection='column'
								alignItems='center'
								sx={{ mt: 3, mb: 1, mx: "auto" }}>
								<label
									htmlFor='icon-button-file1'
									className='bgColor'
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										margin: "0 9px",
										borderRadius: 5,
									}}>
									<Input
										accept='image/*'
										id='icon-button-file1'
										type='file'
										onChange={uploadImage1}
									/>
									<Typography
										sx={{
											my: 2,
											ml: 0,
											mr: 2,
											color: "white",
											fontWeight: "bold",
										}}
										variant='button'
										component='div'
										gutterBottom>
										إخراج قيد فردي أو عائلي * 1
									</Typography>
									<IconButton
										color='primary'
										aria-label='upload picture'
										component='span'>
										<AttachFileIcon
											fontSize='large'
											sx={{ fontWeight: "bold" }}
										/>
									</IconButton>
								</label>

								{loading1 ? (
									<Box sx={{ my: 2 }}>
										<CircularProgress className='textColor' />
									</Box>
								) : (
									<img src={imageLink1} style={{ width: "300px" }} alt='' />
								)}
							</Box>
						)}
						{imageLink1 && (
							<Box
								display='flex'
								flexDirection='column'
								alignItems='center'
								sx={{ mt: 3, mb: 1, mx: "auto" }}>
								<label
									htmlFor='icon-button-file2'
									className='bgColor'
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										margin: "0 9px",
										borderRadius: 5,
									}}>
									<Input
										accept='image/*'
										id='icon-button-file2'
										type='file'
										onChange={uploadImage2}
									/>
									<Typography
										sx={{
											my: 2,
											ml: 0,
											mr: 2,
											color: "white",
											fontWeight: "bold",
										}}
										variant='button2'
										component='div'
										gutterBottom>
										صورة عن العلامات المدرسيةً * 2
									</Typography>
									<IconButton
										color='primary'
										aria-label='upload picture'
										component='span'>
										<AttachFileIcon
											fontSize='large'
											sx={{ fontWeight: "bold" }}
										/>
									</IconButton>
								</label>

								{loading2 ? (
									<Box sx={{ my: 2 }}>
										<CircularProgress className='textColor' />
									</Box>
								) : (
									<img src={imageLink2} style={{ width: "300px" }} alt='' />
								)}
							</Box>
						)}

						<TextField
							sx={{ display: "none" }}
							value={imageLink1}
							variant='standard'
							{...register("location")}
						/>
						<TextField
							sx={{ display: "none" }}
							value={imageLink1}
							variant='standard'
							{...register("imageLink1")}
						/>
						<TextField
							sx={{ display: "none" }}
							value={imageLink2}
							variant='standard'
							{...register("imageLink2")}
						/>
						{imageLink2 && (
							<Grid container spacing={3}>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='a'
										label='إسم الطالب'
										fullWidth
										variant='standard'
										{...register("a", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='b'
										label='إسم الأب'
										fullWidth
										variant='standard'
										{...register("b", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='c'
										label='إسم العائلة'
										fullWidth
										variant='standard'
										{...register("c", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='d'
										label='تاريخ الولادة'
										fullWidth
										variant='standard'
										{...register("d", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='e'
										label='إسم الأم'
										fullWidth
										variant='standard'
										{...register("e", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='f'
										label='رقم القيد'
										fullWidth
										variant='standard'
										{...register("f", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='g'
										label='إسم المدرسة'
										fullWidth
										variant='standard'
										{...register("g", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='h'
										label='الصف'
										fullWidth
										variant='standard'
										{...register("h", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='i'
										label='المستوى التعليمي'
										fullWidth
										variant='standard'
										{...register("i", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='j'
										label='هل يوجد حالة صحية للتلميذ'
										fullWidth
										variant='standard'
										{...register("j", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='k'
										label='هل يوجد حساسية'
										fullWidth
										variant='standard'
										{...register("k", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='l'
										label='هل يوجد مرض مزمن'
										fullWidth
										variant='standard'
										{...register("l", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='m'
										label='ما هو العنوان السكن'
										fullWidth
										variant='standard'
										{...register("m", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='n'
										label='رقم الهاتف'
										fullWidth
										variant='standard'
										{...register("n", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										name='o'
										label='البريد الإلكتروني'
										fullWidth
										variant='standard'
										{...register("o")}
									/>
								</Grid>
								<Grid item md={12} sm={12} xs={12}>
									<Button
										type='submit'
										variant='contained'
										sx={{
											width: "100%",
											mb: 2,
											px: 3,
											fontWeight: "bold",
											borderRadius: "25px",
										}}>
										إرسال الطلب
									</Button>
								</Grid>
							</Grid>
						)}
					</form>{" "}
				</Box>
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Course;
