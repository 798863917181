import {
	Button,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const AllReviews = () => {
	const [reviews, setReviews] = useState([]);
	const [deleted, setDeleted] = useState(false);
	useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/review`)
			.then((res) => res.json())
			.then((data) => setReviews(data.reverse()));
	}, [deleted]);

	const handleDelete = (id) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(
						`https://sawa4leb.bcard.a2hosted.com/review/${id}`,
					)
					.then(function (response) {
						Swal.fire("Deleted!", "That Review has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	let count = 1;
	const headers = [
		{ label: "ID", key: "_id" },
		{ label: "Name", key: "userName" },
		{ label: "Rating", key: "star" },
		{ label: "Review", key: "userReview" },
		{ label: "User Photo", key: "userPhoto" },
	];

	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }}>
			<Grid>
				<Typography
					className='textColor'
					sx={{ mb: 3, fontWeight: "bold" }}
					variant='h4'
					component='div'
					gutterBottom>
					All Reviews
				</Typography>
				<Button
					className='csvBtn button border'
					sx={{
						fontWeight: "bold",
						border: "2px solid white !important",
						backgroundColor: "transparent",
						borderRadius: "25px",
						mb: 2.5,
					}}
					variant='contained'>
					<FileDownloadIcon />
					<CSVLink
						filename={"All Reviews.csv"}
						data={reviews}
						headers={headers}
						style={{
							textDecoration: "none",
							color: "white",
							fontWeight: "bold",
							marginLeft: 5,
						}}>
						Download Forms
					</CSVLink>
				</Button>
				<Grid item xs={12} md={12}>
					<Paper
						className='container'
						sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
						<Table size='small' aria-label='a dense table'>
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>No</TableCell>
									<TableCell align='left'>Photo</TableCell>
									<TableCell align='left'>Name</TableCell>
									<TableCell align='left'>Reviews</TableCell>
									<TableCell align='left'>Action</TableCell>
								</TableRow>
							</TableHead>
							{reviews?.length > 0 ? (
								<TableBody sx={{ td: { py: 1 } }}>
									{reviews.map((review) => (
										<TableRow
											key={review?._id}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}>
											<TableCell align='left'>{count++}</TableCell>
											<TableCell>
												<img
													src={review?.userPhoto || "N/A"}
													alt=''
													width='50px'
													height='50px'
													style={{ borderRadius: "50%" }}
												/>
											</TableCell>
											<TableCell align='left'>
												{review?.userName || "N/A"}
											</TableCell>
											<TableCell align='left'>
												{review?.userReview || "N/A"}
											</TableCell>
											<TableCell align='left'>
												<Button
													className='button border'
													onClick={() => handleDelete(review?._id)}
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
													}}
													variant='contained'>
													<DeleteIcon />
												</Button>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							) : (
								<TableHead sx={{ th: { fontWeight: "bold" } }}>
									<TableRow>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
									</TableRow>
								</TableHead>
							)}
						</Table>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	);
};

export default AllReviews;
