import React from "react";
import Reviews from "./Components/Reviews/Reviews";
import About from "./Components/About/About";
import Banner from "./Components/Banner/Banner";
import Buttons from "./Components/Buttons/Buttons";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import Gallery from "./Components/Gallery/Gallery";
import CopyRight from "./Components/CopyRight/CopyRight";

const HomepageMain = () => {
	return (
		<div>
			<Banner />
			<Buttons />
			<About />
			<Gallery />
			<Reviews />
			<Footer />
			<Contact />
			<CopyRight />
		</div>
	);
};

export default HomepageMain;
