import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
	const location = useNavigate();
	return (
		<Grid
			container
			direction='column'
			alignItems='center'
			justifyContent='center'
			className='textColor'
			style={{ minHeight: "100vh" }}>
			<Typography variant='h1' component='div' sx={{ fontWeight: 900 }}>
				404
			</Typography>
			<Typography
				variant='h2'
				component='div'
				gutterBottom
				sx={{ fontWeight: 900 }}>
				Opps!
			</Typography>
			<Typography
				variant='h3'
				component='div'
				gutterBottom
				sx={{ fontWeight: 900 }}>
				Page Not Found
			</Typography>
			<Button
				onClick={() => location("/")}
				variant='contained'
				sx={{
					mt: 3,
					mb: 2,
				}}>
				Back to Home
			</Button>
		</Grid>
	);
};

export default NotFound;
