import {
	Button,
	Container,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
	IconButton,
	FormControl,
	Select,
	MenuItem,
	FormHelperText,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import axios from "axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { styled } from "@mui/material/styles";

const Contact = () => {
	const form = useRef();

	const [headline, setHeadline] = React.useState([]);
	React.useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/headline/headline`)
			.then((res) => res.json())
			.then((data) => setHeadline(data));
	}, []);
	const [cause, setCause] = React.useState("");

	const handleChange = (event) => {
		setCause(event.target.value);
	};
	const [imageLink2, setImageLink2] = useState(null);
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, reset } = useForm();

	const [causes, setCauses] = useState();
	React.useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/causes`)
			.then((res) => res.json())
			.then((data) => setCauses(data));
	}, []);
	const [submitting, setSubmitting] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "contact");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/dqsndznxf/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});
	const onSubmit = ({ message, phoneNumber, subject, userEmail, userName }) => {
		const data = {
			imageLink: imageLink2,
			causes: cause,
			message,
			phoneNumber,
			subject,
			userEmail,
			userName,
		};
		setSubmitting(true);
		emailjs
			.sendForm(
				"service_bp2h35d",
				"template_8h0yema",
				form.current,
				"user_i5224CgRgcclb82ilRvnO",
			)
			.then(
				(result) => {
					axios
						.post(
							`https://sawa4leb.bcard.a2hosted.com/emails`,
							data,
						)
						.then(function (response) {
							setSubmitting(false);
							Swal.fire({
								icon: "success",
								title: "تم إرسال رسائلك بنجاح",
								showConfirmButton: true,
								timer: 1500,
							});
							reset();
						})
						.catch(function (error) {
							console.log(error);
						});
				},
				(error) => {
					console.log(error.text);
				},
			);
	};

	return (
		<Container sx={{ py: 5 }}>
			<Accordion sx={{ borderRadius: "10px" }}>
				<AccordionSummary
					aria-controls='panel1a-content'
					id='panel1a-header'
					sx={{ borderRadius: "10px" }}>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Typography
							sx={{ fontWeight: 900, fontSize: "1.9rem" }}
							variant='h4'
							component='div'
							gutterBottom>
							{headline?.contact}
						</Typography>

						<Typography variant='body' component='div'>
							الرجاء التواصل معنا هنا وتعبئة الطلب بعد إرفاق صورة إخراج القيد
							العائلي.
						</Typography>
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<Typography>
						<Grid container spacing={2}>
							<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
								<form
									ref={form}
									onSubmit={handleSubmit(onSubmit)}
									method='post'>
									<TextField
										sx={{ display: "none" }}
										value={imageLink2}
										variant='standard'
										{...register("imageLink")}
									/>
									<Grid container spacing={2}>
										<Grid item md={12} xs={12}>
											<FormControl sx={{ m: 1, minWidth: 120 }}>
												<Select
													value={cause}
													onChange={handleChange}
													displayEmpty>
													<MenuItem value=''>
														<em>Select</em>
													</MenuItem>
													{causes?.map((cause, key) => (
														<MenuItem
															key={key}
															sx={{ my: 0.4, textAlign: "center" }}
															value={cause.value}>
															{cause.name}
														</MenuItem>
													))}
												</Select>
												<FormHelperText>الرجاء إختيار الموضوع</FormHelperText>
											</FormControl>
										</Grid>

										<Grid item md={12} xs={12}>
											{cause && (
												<Box
													display='flex'
													flexDirection='column'
													alignItems='center'
													sx={{ mb: 1, mx: "auto" }}>
													<label
														htmlFor='icon-button-file'
														className='bgColor'
														style={{
															display: "flex",
															alignItems: "center",
															justifyContent: "center",
															margin: "0 9px",
															borderRadius: 5,
														}}>
														<Input
															accept='image/*'
															id='icon-button-file'
															type='file'
															onChange={uploadImage}
														/>
														<Typography
															sx={{ m: 2, color: "white" }}
															variant='h6'
															component='div'
															gutterBottom>
															إرفاق صورة*
														</Typography>
														<IconButton
															color='primary'
															aria-label='upload picture'
															component='span'>
															<AttachFileIcon
																fontSize='large'
																sx={{ fontWeight: "bold" }}
															/>
														</IconButton>
													</label>

													{loading ? (
														<Box sx={{ my: 2 }}>
															<CircularProgress className='textColor' />
														</Box>
													) : (
														<img
															src={imageLink2}
															style={{ width: "300px" }}
															alt=''
														/>
													)}
												</Box>
											)}
										</Grid>

										{imageLink2 && (
											<Grid item md={6} xs={12}>
												<Grid container spacing={2}>
													<Grid item md={12} xs={12}>
														<TextField
															required
															sx={{ width: "100%" }}
															id='outlined-basic'
															name='UserName'
															label='أدخل أسمك*'
															{...register("userName", { required: true })}
														/>
													</Grid>
													<Grid item md={12} xs={12}>
														<TextField
															sx={{ width: "100%" }}
															id='outlined-basic'
															name='UserEmail'
															type='email'
															label='أدخل عنوان بريدك الالكتروني'
															{...register("userEmail", { required: true })}
														/>
													</Grid>
													<Grid item md={12} xs={12}>
														<TextField
															required
															sx={{ width: "100%" }}
															id='outlined-basic'
															name='phoneNumber'
															type='number'
															label='رقم التليفون*'
															{...register("phoneNumber", { required: true })}
														/>
													</Grid>
													<Grid item md={12} xs={12}>
														<TextField
															required
															sx={{ width: "100%", mb: { md: 2, xs: 0 } }}
															id='outlined-basic'
															label='موضوع*'
															name='Subject'
															{...register("subject", { required: true })}
														/>
													</Grid>
												</Grid>
											</Grid>
										)}
										{imageLink2 && (
											<Grid item md={6} xs={12}>
												<TextField
													required
													sx={{ width: "100%", mb: 2 }}
													id='"outlined-multiline-flexible'
													label='رسالتك*'
													name='Message'
													multiline
													rows={10.2}
													{...register("message", { required: true })}
												/>
											</Grid>
										)}
									</Grid>
									{imageLink2 && (
										<Button
											className='sendButton'
											type='submit'
											variant='contained'
											sx={{
												width: "100%",
												mb: 2,
												px: 3,
												fontWeight: "bold",
												borderRadius: "25px",
											}}>
											إرسال الطلب
										</Button>
									)}
								</form>
							</Grid>
						</Grid>
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Contact;
