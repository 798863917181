import {
	Button,
	Container,
	Grid,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import axios from "axios";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";

const MailCause = () => {
	const { register, handleSubmit, reset } = useForm();
	const [causes, setCauses] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [deleted, setDeleted] = useState(false);
	React.useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/causes`)
			.then((res) => res.json())
			.then((data) => setCauses(data.reverse()));
	}, [submitting, deleted]);
	const onSubmit2 = ({ values, name }) => {
		const data = {
			value: values.replace(/ /g, "").toLowerCase(),
			name,
		};
		axios
			.post(`https://sawa4leb.bcard.a2hosted.com/causes`, data)
			.then(function (response) {
				setSubmitting(true);
				Swal.fire({
					icon: "success",
					title: "Successfully Added",
					showConfirmButton: true,
					timer: 2500,
				});
				reset();
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(
						`https://sawa4leb.bcard.a2hosted.com/causes/${id}`,
					)
					.then(function (response) {
						setDeleted(true);
						Swal.fire("Deleted!", "That field has been deleted.", "success");
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	let count = 1;
	return (
		<Container sx={{ py: 5 }}>
			<Typography
				className='textColor'
				sx={{ mb: 3, fontWeight: "bold" }}
				variant='h4'
				component='div'
				gutterBottom>
				Form Cause Dropdown
			</Typography>
			<Paper
				className='container'
				sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw", pt: 2 }}>
				<form onSubmit={handleSubmit(onSubmit2)} method='post'>
					<Grid container spacing={2}>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<TextField
								sx={{ width: "100%" }}
								id='outlined-basic'
								name='value'
								label='Value (In English)'
								{...register("values", { required: true })}
							/>
						</Grid>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<TextField
								sx={{ width: "100%" }}
								id='outlined-basic'
								name='name'
								label='Name (Any language)'
								{...register("name", { required: true })}
							/>
						</Grid>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<Button
								className='sendButton'
								type='submit'
								variant='contained'
								sx={{
									width: "100%",
									mb: 2,
									px: 3,
									fontWeight: "bold",
									borderRadius: "25px",
								}}>
								Add
							</Button>
						</Grid>
					</Grid>
				</form>
				<Table size='small' aria-label='a dense table'>
					<TableHead sx={{ th: { fontWeight: "bold" } }}>
						<TableRow>
							<TableCell align='left'>No</TableCell>
							<TableCell align='left'>Cause</TableCell>
							<TableCell align='left'>Value</TableCell>
							<TableCell align='left'>Action</TableCell>
						</TableRow>
					</TableHead>
					{causes?.length > 0 ? (
						<TableBody sx={{ td: { py: 1 } }}>
							{causes.map((cause) => (
								<TableRow
									key={cause?._id}
									sx={{
										"&:last-child td, &:last-child th": { border: 0 },
									}}>
									<TableCell align='left'>{count++}</TableCell>
									<TableCell align='left'>{cause?.name}</TableCell>
									<TableCell align='left'>{cause?.value}</TableCell>
									<TableCell align='left'>
										<Button
											className='button border'
											onClick={() => handleDelete(cause?._id)}
											sx={{
												fontWeight: "bold",
												border: "2px solid",
												backgroundColor: "transparent",
												borderRadius: "25px",
											}}
											variant='contained'>
											<DeleteIcon />
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					) : (
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
							</TableRow>
						</TableHead>
					)}
				</Table>
			</Paper>
		</Container>
	);
};

export default MailCause;
