import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
	Backdrop,
	Box,
	Button,
	ButtonGroup,
	CircularProgress,
	Container,
	Divider,
	IconButton,
	Paper,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";
import axios from "axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const Form2 = () => {
	const navigate = useNavigate();
	const form = useRef();
	const [submitting, setSubmitting] = useState(false);
	const [checked, setChecked] = React.useState(null);
	const { register, handleSubmit, reset } = useForm();
	const onSubmit = ({ a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q }) => {
		setSubmitting(true);
		const data = {
			a,
			b,
			c,
			d,
			e,
			f,
			g,
			h,
			i,
			j,
			k,
			l,
			m,
			n,
			o,
			p,
			q,
			imageLink: imageLink2,
			importance: checked,
		};
		emailjs
			.sendForm(
				"service_bp2h35d",
				"template_ggd2f99",
				form.current,
				"user_i5224CgRgcclb82ilRvnO",
			)
			.then(
				(result) => {
					axios
						.post(
							`https://sawa4leb.bcard.a2hosted.com/forms2`,
							data,
						)
						.then(function (response) {
							setSubmitting(false);
							Swal.fire({
								icon: "success",
								title: "شكرا لك وسيتم إعادة توجيهك إلى الصفحة الرئيسية",
								showConfirmButton: true,
								timer: 2500,
							}).then(function () {
								reset();
								navigate("/");
							});
						})
						.catch(function (error) {
							console.log(error);
						});
				},
				(error) => {
					console.log(error.text);
				},
			);
	};
	const [imageLink2, setImageLink2] = useState(null);

	const [loading, setLoading] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "evahassoun");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/dqsndznxf/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});
	return (
		<Container sx={{ pt: 1 }} dir='rtl'>
			<Typography
				variant='h3'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				قلب على قلب
				{/* heart on heart */}
				<Divider className='hr' />
			</Typography>
			<Grid
				container
				spacing={0}
				direction='column'
				alignItems='center'
				justifyContent='center'
				style={{ minHeight: "70vh" }}>
				<Box>
					<form ref={form} onSubmit={handleSubmit(onSubmit)} method='post'>
						<Box
							display='flex'
							flexDirection='column'
							alignItems='center'
							sx={{ mt: 3, mb: 1, mx: "auto" }}>
							<label
								htmlFor='icon-button-file'
								className='bgColor'
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									margin: "0 9px",
									borderRadius: 5,
								}}>
								<Input
									accept='image/*'
									id='icon-button-file'
									type='file'
									onChange={uploadImage}
								/>
								<Typography
									sx={{ m: 2, color: "white" }}
									variant='h6'
									component='div'
									gutterBottom>
									الخطوة الأولى : إرفاق صورة إخراج القيد هنا أولاً *
								</Typography>
								<IconButton
									color='primary'
									aria-label='upload picture'
									component='span'>
									<AttachFileIcon
										fontSize='large'
										sx={{ fontWeight: "bold" }}
									/>
								</IconButton>
							</label>

							{loading ? (
								<Box sx={{ my: 2 }}>
									<CircularProgress className='textColor' />
								</Box>
							) : (
								<img src={imageLink2} style={{ width: "300px" }} alt='' />
							)}
						</Box>
						<TextField
							sx={{ display: "none" }}
							value={imageLink2}
							variant='standard'
							{...register("imageLink")}
						/>

						{imageLink2 && (
							<Box sx={{ mt: 2, backgroundColor: "transparent" }}>
								<Paper
									elevation={3}
									sx={{ width: "fit-content", mx: "auto", p: 4, my: 3 }}>
									<Typography
										variant='h6'
										gutterBottom
										sx={{ fontWeight: "bold", color: "red" }}>
										هل صاحب الطلب ارملة او لديك أيتام؟
									</Typography>
									<ButtonGroup
										variant='contained'
										className='yesNo'
										sx={{ boxShadow: 0, mt: 2 }}>
										{checked === "VVI" ? (
											<button
												disabled
												style={{
													borderRadius: 0,
													marginLeft: 0.5,
													color: "white",
													fontWeight: "bold",
													backgroundColor: "red",
													padding: "0 15px",
												}}>
												نعم ✅
											</button>
										) : (
											<Button
												onClick={() => setChecked("VVI")}
												sx={{ borderRadius: 0, ml: 0.5 }}>
												نعم
											</Button>
										)}
										{checked === "N/A" ? (
											<button
												disabled
												style={{
													borderRadius: 0,
													marginLeft: 0.5,
													color: "white",
													fontWeight: "bold",
													backgroundColor: "red",
													padding: "0 15px",
												}}>
												كلا ❎
											</button>
										) : (
											<Button
												onClick={() => setChecked("N/A")}
												sx={{ borderRadius: 0, mr: 0.5 }}>
												كلا
											</Button>
										)}
									</ButtonGroup>
								</Paper>
							</Box>
						)}
						{checked && (
							<Grid container spacing={3}>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='a'
										label='الإسم'
										fullWidth
										variant='standard'
										{...register("a", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='b'
										label='الشهرة'
										fullWidth
										variant='standard'
										{...register("b", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='c'
										label='إسم الأب'
										fullWidth
										variant='standard'
										{...register("c", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='d'
										label='إسم الأم'
										fullWidth
										variant='standard'
										{...register("d", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='e'
										label='الجنس'
										fullWidth
										variant='standard'
										{...register("e", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='f'
										label='القضاء'
										fullWidth
										variant='standard'
										{...register("f", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='g'
										label='القضاء'
										fullWidth
										variant='standard'
										{...register("g", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='h'
										label='المدينة أو القرية:'
										fullWidth
										variant='standard'
										{...register("h", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='i'
										label='عنوان السكن بالتفصيل:'
										fullWidth
										variant='standard'
										{...register("i", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='j'
										label='رقم الهاتف'
										fullWidth
										variant='standard'
										{...register("j", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='k'
										label='رقم السجل'
										fullWidth
										variant='standard'
										{...register("k", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='l'
										label='مكان السجل'
										fullWidth
										variant='standard'
										{...register("l", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='m'
										label='الوضع الاجتماعي:'
										fullWidth
										variant='standard'
										{...register("m", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='n'
										label='عدد أفراد الأسرة'
										fullWidth
										variant='standard'
										{...register("n", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='o'
										label='تحديد الحالات المرضية الموجودة في الأسرة/ذوي احتياجات خاصة؟'
										fullWidth
										variant='standard'
										{...register("o", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='p'
										label='هل لديكم بطاقة شؤون؟'
										fullWidth
										variant='standard'
										{...register("p", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='q'
										label='هل أحد أفراد الأسرة موظف في القطاع العام/أجهزة أمنية؟'
										fullWidth
										variant='standard'
										{...register("q", { required: true })}
									/>
								</Grid>

								<Grid item md={12} sm={12} xs={12}>
									<Button
										type='submit'
										variant='contained'
										sx={{
											width: "100%",
											mb: 2,
											px: 3,
											fontWeight: "bold",
											borderRadius: "25px",
										}}>
										إرسال الطلب
									</Button>
								</Grid>
							</Grid>
						)}
					</form>
				</Box>
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Form2;
