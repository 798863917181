import {
	Box,
	Button,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ButtonGroup from "@mui/material/ButtonGroup";
import { reactLocalStorage } from "reactjs-localstorage";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const FormSubmissions = () => {
	const getId = reactLocalStorage.getObject("selectedRowVol")?.selected;
	const leveled = reactLocalStorage.getObject("levelVol")?.level;
	const viewed = (id) => {
		reactLocalStorage.setObject("selectedRowVol", { selected: id });
	};
	const [level, setLevel] = useState(leveled || `Level-1`);
	const [forms, setForms] = useState([]);
	useEffect(() => {
		fetch(
			`https://sawa4leb.bcard.a2hosted.com/formslevel/?importance=${level}`,
		)
			.then((res) => res.json())
			.then((data) => setForms(data.reverse()));
	}, [level]);
	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(
						`https://sawa4leb.bcard.a2hosted.com/forms/${id}`,
					)
					.then(function (response) {
						Swal.fire("Deleted!", "That from has been deleted.", "success");
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	let count = 1;
	const headers = [
		{ label: "ID", key: "_id" },
		{ label: "Image Link", key: "imageLink" },
		{ label: "Fullname", key: "fullname" },
		{ label: "Gender", key: "gender" },
		{ label: "Birthdate", key: "birthdate" },
		{ label: "Education Level", key: "educationLevel" },
		{ label: "Address", key: "address" },
		{ label: "Phone", key: "phone" },
		{ label: "Occupation", key: "occupation" },
		{ label: "Reason For Volunteer", key: "reasonForVolunteer" },
		{ label: "Done Volunteer Before?", key: "doneVolunteerbefore" },
		{ label: "Volunteer Time", key: "volunteerTime" },
		{ label: "Date", key: "date" },
		{ label: "Draw Attention", key: "drawAttention" },
		{ label: "Activities", key: "activities" },
		{ label: "Importance Level", key: "importance" },
		{ label: "Lighting", key: "lighting" },
		{ label: "Skills", key: "skills" },
	];

	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }}>
			<Grid>
				<Typography
					className='textColor'
					sx={{ mb: 3, fontWeight: "bold" }}
					variant='h4'
					component='div'
					gutterBottom>
					All Volunteer Forms
				</Typography>
				<Box sx={{ mb: 4 }}>
					<Typography
						sx={{ fontWeight: "bold" }}
						variant='h6'
						gutterBottom
						className='textColor'>
						Sort By Importance Level
					</Typography>
					<ButtonGroup
						variant='contained'
						aria-label='outlined primary button group'>
						{level === `Level-1` ? (
							<button
								disabled
								style={{ backgroundColor: "red", color: "white" }}>
								High
							</button>
						) : (
							<Button
								onClick={() => {
									setLevel(`Level-1`);
									reactLocalStorage.setObject("levelVol", {
										level: `Level-1`,
									});
								}}>
								High
							</Button>
						)}
						{level === `Level-2` ? (
							<button
								disabled
								style={{ backgroundColor: "red", color: "white" }}>
								Medium
							</button>
						) : (
							<Button
								onClick={() => {
									setLevel(`Level-2`);
									reactLocalStorage.setObject("levelVol", {
										level: `Level-2`,
									});
								}}>
								Medium
							</Button>
						)}
						{level === `Level-3` ? (
							<button
								disabled
								style={{ backgroundColor: "red", color: "white" }}>
								Low
							</button>
						) : (
							<Button
								onClick={() => {
									setLevel(`Level-3`);
									reactLocalStorage.setObject("levelVol", {
										level: `Level-3`,
									});
								}}>
								Low
							</Button>
						)}
						{level === `N/A` ? (
							<button
								disabled
								style={{ backgroundColor: "red", color: "white" }}>
								N/A
							</button>
						) : (
							<Button
								onClick={() => {
									setLevel(`N/A`);
									reactLocalStorage.setObject("levelVol", {
										level: `N/A`,
									});
								}}>
								N/A
							</Button>
						)}
					</ButtonGroup>
				</Box>
				<Button
					className='csvBtn button border'
					sx={{
						fontWeight: "bold",
						border: "2px solid white !important",
						backgroundColor: "transparent",
						borderRadius: "25px",
						mb: 2.5,
					}}
					variant='contained'>
					<FileDownloadIcon />
					<CSVLink
						filename={"All Volunteer Forms.csv"}
						data={forms}
						headers={headers}
						style={{
							textDecoration: "none",
							color: "white",
							fontWeight: "bold",
							marginLeft: 5,
						}}>
						Download Forms
					</CSVLink>
				</Button>

				<Paper
					className='container'
					sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
					<Table size='small' aria-label='a dense table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>صورة فوتوغرافية</TableCell>
								<TableCell align='left'>الإسم الثلاثي</TableCell>
								{/* 	<TableCell align='left'>التاريخ</TableCell>
								<TableCell align='left'>التلفون</TableCell>
								<TableCell align='left'>العنوان</TableCell>
								<TableCell align='left'>المستوى التعليمي</TableCell>
								<TableCell align='left'>تاريخ الولادة/العمر</TableCell>
								<TableCell align='left'>ذكر/أنثى</TableCell>
								<TableCell align='left'>المهنة</TableCell>
								<TableCell align='left'>
									أهم المهارات و القدرات الشخصية لدى المتطوع
								</TableCell>
								<TableCell align='left'>
									اوجه الاهتمام بالجمعية و أهدافها
								</TableCell>
								<TableCell align='left'>
									أهم أنشطة الجمعية التي تود المساهمة فيها
								</TableCell>
								<TableCell align='left'>
									هل قمت بأعمال تطوعية من قبل/ و ما هو؟
								</TableCell>
								<TableCell align='left'>
									ما هو الوقت المتاح لديك للتطوع به
								</TableCell>
								<TableCell align='left'>سبب التطوع</TableCell>
								<TableCell align='left'>الامضاء</TableCell> */}
								<TableCell align='center'>Action</TableCell>
							</TableRow>
						</TableHead>
						{forms?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{forms.map((form) => (
									<TableRow
										className={form?._id === getId ? "selectedRow" : ""}
										key={form?._id}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										<TableCell align='left'>{count++}</TableCell>

										<TableCell align='left'>
											<img
												src={form?.imageLink || "N/A"}
												alt=''
												width='50px'
												height='50px'
												style={{ borderRadius: "50%" }}
											/>
										</TableCell>

										<TableCell align='left'>{form?.fullname}</TableCell>

										{/* 										<TableCell align='left'>{form?.date}</TableCell>
										<TableCell align='left'>{form?.phone}</TableCell>
										<TableCell align='left'>{form?.address}</TableCell>
										<TableCell align='left'>{form?.educationLevel}</TableCell>
										<TableCell align='left'>{form?.birthdate}</TableCell>
										<TableCell align='left'>{form?.gender}</TableCell>
										<TableCell align='left'>{form?.occupation}</TableCell>
										<TableCell align='left'>{form?.skills}</TableCell>
										<TableCell align='left'>{form?.drawAttention}</TableCell>
										<TableCell align='left'>{form?.activities}</TableCell>
										<TableCell align='left'>
											{form?.doneVolunteerbefore}
										</TableCell>
										<TableCell align='left'>{form?.volunteerTime}</TableCell>
										<TableCell align='left'>
											{form?.reasonForVolunteer}
										</TableCell>
										<TableCell align='left'>{form?.lighting}</TableCell> */}
										<TableCell align='center'>
											<Link
												onClick={() => viewed(form?._id)}
												to={`/dashboard/formsubmissions/${form?._id}`}
												style={{ textDecoration: "none" }}>
												<Button
													className='button border'
													sx={{
														fontWeight: "bold",
														border: "2px solid white !important",
														backgroundColor: "transparent",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<RemoveRedEyeIcon />
												</Button>
											</Link>

											<Button
												className='button border'
												onClick={() => handleDelete(form?._id)}
												sx={{
													fontWeight: "bold",
													border: "2px solid white !important",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									{/* 
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell> */}
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</Grid>
		</Container>
	);
};

export default FormSubmissions;
