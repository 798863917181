import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Backdrop, Button, CircularProgress, Container } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";

const ButtonsLink = () => {
	const [submitting, setSubmitting] = useState(false);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			email: "",
			facebook: "",
			whatsapp: "",
			youtube: "",
			sms: "",
			twitter: "",
			instagram: "",
		},
	});
	const type = "links";
	useEffect(() => {
		axios
			.get(`https://sawa4leb.bcard.a2hosted.com/links/${type}`)
			.then((res) => {
				reset(res.data);
			});
	}, [reset]);
	const onSubmit = ({
		email,
		facebook,
		whatsapp,
		youtube,
		sms,
		twitter,
		instagram,
	}) => {
		setSubmitting(true);
		const data = {
			type: "links",
			email,
			facebook,
			whatsapp,
			youtube,
			sms,
			twitter,
			instagram,
		};
		axios
			.put(
				`https://sawa4leb.bcard.a2hosted.com/links/${type}`,
				data,
			)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your links Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Buttons links
			</Typography>

			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3} justifyContent='center'>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Facebook'
							label='Facebook'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("facebook", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Email'
							label='Email'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("email", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='WhatsApp'
							label='WhatsApp'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("whatsapp", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Youtube'
							label='Youtube'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("youtube", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='SMS'
							label='SMS'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("sms", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Twitter'
							label='Twitter'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("twitter", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='Instagram'
							label='Instagram'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("instagram", { required: true })}
						/>
					</Grid>

					<Grid item md={12} sm={12} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default ButtonsLink;
