import {
	Button,
	Container,
	Grid,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import axios from "axios";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";

const SingleMail = () => {
	let ref = React.createRef();
	const { id } = useParams();
	const [data, setData] = useState();

	const { register, reset } = useForm({
		defaultValues: {
			message: "",
			phoneNumber: "",
			subject: "",
			userEmail: "",
			userName: "",
		},
	});
	useEffect(() => {
		axios
			.get(`https://sawa4leb.bcard.a2hosted.com/emails/${id}`)
			.then((res) => {
				reset(res.data);
				setData(res.data);
			});
	}, [id, reset]);
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Container sx={{ py: 1 }} dir='rtl'>
			<Button
				onClick={handleOpen}
				className=' button border'
				sx={{
					fontWeight: "bold",
					border: "2px solid",
					backgroundColor: "transparent",
					borderRadius: "25px",
					width: "180px",
				}}>
				Open PDF
				<OpenInFullIcon sx={{ mr: 1 }} />
			</Button>
			<Modal
				dir='rtl'
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: "90vw",
						height: "90vh",
						bgcolor: "white",
						border: "2px solid #000",
						boxShadow: 24,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<ReactToPrint
						onAfterPrint={() => {
							setOpen(false);
							Swal.fire({
								icon: "success",
								title: "PDF Saved",
								showConfirmButton: true,
								timer: 2700,
							});
						}}
						trigger={() => (
							<Button
								className='csvBtn button border'
								sx={{
									fontWeight: "bold",
									border: "2px solid white !important",
									backgroundColor: "transparent",
									borderRadius: "25px",
									width: "180px",
									my: 1,
								}}
								variant='contained'>
								Save PDF
								<FileDownloadIcon sx={{ mr: 1 }} />
							</Button>
						)}
						content={() => ref}
					/>
					<Box
						sx={{
							overflow: "scroll !important",
							width: "90vw",
							height: "90vh",
							bgcolor: "white",
							border: "2px solid #000",
							boxShadow: 24,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<div className='formPagePdf' ref={(el) => (ref = el)}>
							<div className='page' dir='rtl'>
								<Grid
									container
									spacing={3}
									dir='ltr'
									sx={{ justifyContent: "flex-end", alignItems: "center" }}>
									<Grid item>
										<Typography variant='h4' sx={{ fontWeight: "bold" }}>
											جمعية المرأة السفيرة
										</Typography>
										<Typography>
											العمل التطوعي هو حاجة إجتماعية تحقق الترابط، التآلف و
											التآخي بين أفرد المجتمع
										</Typography>
									</Grid>

									<Grid item>
										<img
											style={{ width: "70px", height: "70px" }}
											src='https://res.cloudinary.com/dqsndznxf/image/upload/v1640213898/evahassoun/uqmromk3wlp4kf0eznxb.png'
											alt=''
										/>
									</Grid>
								</Grid>
								<hr
									style={{
										border: "1.5px solid",
										margin: "0.5rem 1rem 0 1rem ",
									}}
								/>
								<Typography
									variant='h4'
									gutterBottom
									sx={{
										textAlign: "center",
										fontWeight: "bold",
										mt: 1.5,
									}}>
									رسائل
								</Typography>
								<div>
									<Box>
										<div style={{ display: "flex", justifyContent: "center" }}>
											<img className='pdfImg' src={data?.imageLink} alt='' />
										</div>
									</Box>
									<Box className='formData' sx={{ mt: 1 }}>
										<div className='inputField'>
											<span>اسم االمستخدم : </span>
											<span className='p'>
												<span>{data?.userName}</span>
											</span>
										</div>
										<div className='inputField'>
											<span>رقم التليفون : </span>
											<span className='p'>
												<span>{data?.phoneNumber}</span>
											</span>
										</div>

										<div className='inputField'>
											<span>أدخل عنوان بريدك الالكتروني : </span>
											<span className='p'>
												<span>{data?.userEmail}</span>
											</span>
										</div>
										<div className='inputField'>
											<span>الأسباب : </span>
											<span className='p'>
												<span>{data?.causes}</span>
											</span>
										</div>

										<div className='inputField'>
											<span>موضوع : </span>
											<span className='p'>
												<span>{data?.subject}</span>
											</span>
										</div>
										<div className='inputField'></div>
										<div className='inputField'>
											<span>رسالتك : </span>
											<span className='p'>
												<span>{data?.message}</span>
											</span>
										</div>
									</Box>
								</div>
							</div>
						</div>
					</Box>
				</Box>
			</Modal>

			<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					sx={{ my: 2, mx: "auto" }}>
					<img
						src={data?.imageLink}
						className='borderColor'
						style={{ width: "250px", border: "5px solid" }}
						alt=''
					/>
				</Box>
				<Typography variant='h4' gutterBottom className='textColor'>
					{data?.userName}
				</Typography>
				<Typography
					variant='h6'
					gutterBottom
					className='textColor'
					sx={{ mb: 2 }}>
					Cause : <b>{data?.causes || "N/A"}</b>
				</Typography>
				<Grid container spacing={2}>
					<Grid item md={12} xs={12}>
						<TextField
							sx={{ width: "100%" }}
							id='outlined-basic'
							name='UserEmail'
							disabled
							InputLabelProps={{
								shrink: true,
							}}
							type='email'
							label='أدخل عنوان بريدك الالكتروني'
							{...register("userEmail", { required: true })}
						/>
					</Grid>
					<Grid item md={12} xs={12}>
						<TextField
							required
							sx={{ width: "100%" }}
							id='outlined-basic'
							name='phoneNumber'
							disabled
							type='number'
							InputLabelProps={{
								shrink: true,
							}}
							label='رقم التليفون*'
							{...register("phoneNumber", { required: true })}
						/>
					</Grid>
					<Grid item md={12} xs={12}>
						<TextField
							required
							sx={{ width: "100%", mb: { md: 2, xs: 0 } }}
							id='outlined-basic'
							label='موضوع*'
							disabled
							name='Subject'
							InputLabelProps={{
								shrink: true,
							}}
							{...register("subject", { required: true })}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
				<TextField
					required
					sx={{ width: "100%", my: 2 }}
					id='"outlined-multiline-flexible'
					label='رسالتك*'
					name='Message'
					disabled
					multiline
					InputLabelProps={{
						shrink: true,
					}}
					rows={10.2}
					{...register("message", { required: true })}
				/>
			</Grid>
		</Container>
	);
};

export default SingleMail;
