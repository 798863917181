import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Box, Container, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ReactToPrint from "react-to-print";

const SingleFormSubmission = () => {
	let ref = React.createRef();
	const { id } = useParams();
	const [data, setData] = useState();

	const { register, reset } = useForm({
		defaultValues: {
			fullname: "",
			date: "",
			phone: "",
			address: "",
			educationLevel: "",
			birthdate: "",
			gender: "",
			occupation: "",
			skills: "",
			drawAttention: "",
			activities: "",
			doneVolunteerbefore: "",
			volunteerTime: "",
			reasonForVolunteer: "",
			lighting: "",
		},
	});
	useEffect(() => {
		axios
			.get(`https://sawa4leb.bcard.a2hosted.com/forms/${id}`)
			.then((res) => {
				reset(res.data);
				setData(res.data);
			});
	}, [id, reset]);
	const level1 = () => {
		const data = {
			importance: "Level-1",
		};
		axios
			.put(
				`https://sawa4leb.bcard.a2hosted.com/formslevel/${id}`,
				data,
			)
			.then(function (response) {
				Swal.fire({
					icon: "success",
					title: "Importance level changed Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const level2 = () => {
		const data = {
			importance: "Level-2",
		};
		axios
			.put(
				`https://sawa4leb.bcard.a2hosted.com/formslevel/${id}`,
				data,
			)
			.then(function (response) {
				Swal.fire({
					icon: "success",
					title: "Importance level changed Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const level3 = () => {
		const data = {
			importance: "Level-3",
		};
		axios
			.put(
				`https://sawa4leb.bcard.a2hosted.com/formslevel/${id}`,
				data,
			)
			.then(function (response) {
				Swal.fire({
					icon: "success",
					title: "Importance level changed Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Container sx={{ py: 1 }} dir='rtl'>
			<Button
				onClick={handleOpen}
				className=' button border'
				sx={{
					fontWeight: "bold",
					border: "2px solid",
					backgroundColor: "transparent",
					borderRadius: "25px",
					width: "180px",
				}}>
				Open PDF
				<OpenInFullIcon sx={{ mr: 1 }} />
			</Button>
			<Modal
				dir='rtl'
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: "90vw",
						height: "90vh",
						bgcolor: "white",
						border: "2px solid #000",
						boxShadow: 24,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<ReactToPrint
						onAfterPrint={() => {
							setOpen(false);
							Swal.fire({
								icon: "success",
								title: "PDF Saved",
								showConfirmButton: true,
								timer: 2700,
							});
						}}
						trigger={() => (
							<Button
								className='csvBtn button border'
								sx={{
									fontWeight: "bold",
									border: "2px solid white !important",
									backgroundColor: "transparent",
									borderRadius: "25px",
									width: "180px",
									my: 1,
								}}
								variant='contained'>
								Save PDF
								<FileDownloadIcon sx={{ mr: 1 }} />
							</Button>
						)}
						content={() => ref}
					/>
					<Box
						sx={{
							overflow: "scroll !important",
							width: "90vw",
							height: "90vh",
							bgcolor: "white",
							border: "2px solid #000",
							boxShadow: 24,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<div className='formPagePdf' ref={(el) => (ref = el)}>
							<div className='page' dir='rtl'>
								<Grid
									container
									spacing={3}
									dir='ltr'
									sx={{ justifyContent: "flex-end", alignItems: "center" }}>
									<Grid item>
										<Typography variant='h4' sx={{ fontWeight: "bold" }}>
											جمعية المرأة السفيرة
										</Typography>
										<Typography>
											العمل التطوعي هو حاجة إجتماعية تحقق الترابط، التآلف و
											التآخي بين أفرد المجتمع
										</Typography>
									</Grid>

									<Grid item>
										<img
											style={{ width: "70px", height: "70px" }}
											src='https://res.cloudinary.com/dqsndznxf/image/upload/v1640213898/evahassoun/uqmromk3wlp4kf0eznxb.png'
											alt=''
										/>
									</Grid>
								</Grid>
								<hr
									style={{
										border: "1.5px solid",
										margin: "0.5rem 1rem 0 1rem ",
									}}
								/>
								<Typography
									variant='h4'
									gutterBottom
									sx={{
										textAlign: "center",
										fontWeight: "bold",
										mt: 1.5,
									}}>
									نماذج المتطوعين
								</Typography>
								<div>
									<Box>
										<div style={{ display: "flex", justifyContent: "center" }}>
											<img className='pdfImg' src={data?.imageLink} alt='' />
										</div>
									</Box>
									<Box className='formData' sx={{ mt: 1 }}>
										<div className='inputField'>
											<span>الإسم الثلاثي : </span>
											<span className='p'>
												<span>{data?.fullname} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>التاريخ : </span>
											<span className='p'>
												<span>{data?.date} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>التلفون : </span>
											<span className='p'>
												<span>{data?.phone} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>العنوان : </span>
											<span className='p'>
												<span>{data?.address} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>المستوى التعليمي : </span>
											<span className='p'>
												<span>{data?.educationLevel} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>تاريخ الولادة/العمر : </span>
											<span className='p'>
												<span>{data?.birthdate} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>ذكر/أنثى : </span>
											<span className='p'>
												<span>{data?.gender} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>المهنة : </span>
											<span className='p'>
												<span>{data?.occupation} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>أهم المهارات و القدرات الشخصية لدى المتطوع : </span>
											<span className='p'>
												<span>{data?.skills} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>اوجه الاهتمام بالجمعية و أهدافها : </span>
											<span className='p'>
												<span>{data?.drawAttention}</span>
											</span>
										</div>
										<div className='inputField'>
											<span>أهم أنشطة الجمعية التي تود المساهمة فيها : </span>
											<span className='p'>
												<span>{data?.activities} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>هل قمت بأعمال تطوعية من قبل/ و ما هو؟ : </span>
											<span className='p'>
												<span>{data?.doneVolunteerbefore} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>ما هو الوقت المتاح لديك للتطوع به : </span>
											<span className='p'>
												<span>{data?.volunteerTime} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>سبب التطوع : </span>
											<span className='p'>
												<span>{data?.reasonForVolunteer} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>الامضاء : </span>
											<span className='p'>
												<span>{data?.lighting} </span>
											</span>
										</div>
									</Box>
								</div>
							</div>
						</div>
					</Box>
				</Box>
			</Modal>
			<Box sx={{ mb: 2 }}>
				<Typography
					sx={{ fontWeight: "bold" }}
					variant='h6'
					gutterBottom
					className='textColor'>
					Set Importance Level
				</Typography>
				<ButtonGroup
					variant='contained'
					aria-label='outlined primary button group'>
					{data?.importance === "Level-1" ? (
						<button disabled style={{ backgroundColor: "red", color: "white" }}>
							High
						</button>
					) : (
						<Button onClick={() => level1()}>High</Button>
					)}
					{data?.importance === "Level-2" ? (
						<button disabled style={{ backgroundColor: "red", color: "white" }}>
							Medium
						</button>
					) : (
						<Button onClick={() => level2()}>Medium</Button>
					)}
					{data?.importance === "Level-3" ? (
						<button disabled style={{ backgroundColor: "red", color: "white" }}>
							Low
						</button>
					) : (
						<Button onClick={() => level3()}>Low</Button>
					)}
				</ButtonGroup>
			</Box>
			<Typography variant='h4' gutterBottom className='textColor'>
				{data?.fullname}
			</Typography>
			<form method='post'>
				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					sx={{ my: 3, mx: "auto" }}>
					<img
						src={data?.imageLink}
						className='borderColor'
						style={{ width: "250px", border: "5px solid" }}
						alt=''
					/>
				</Box>

				<Grid container spacing={3}>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='Fullname'
							label='الإسم الثلاثي'
							fullWidth
							variant='standard'
							{...register("fullname")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='Date'
							label=' التاريخ'
							fullWidth
							variant='standard'
							{...register("date")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='Phone'
							label='التلفون'
							fullWidth
							variant='standard'
							{...register("phone")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='Address'
							label='العنوان'
							fullWidth
							variant='standard'
							{...register("address")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='Education Level'
							label='المستوى التعليمي'
							fullWidth
							variant='standard'
							{...register("educationLevel")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='Birthdate'
							label='تاريخ الولادة/العمر'
							fullWidth
							variant='standard'
							{...register("birthdate")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='Gender'
							label='ذكر/أنثى'
							fullWidth
							variant='standard'
							{...register("gender")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='Occupation'
							label='المهنة'
							fullWidth
							variant='standard'
							{...register("occupation")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='The most important personal skills and abilities of the volunteer'
							label='أهم المهارات و القدرات الشخصية لدى المتطوع'
							fullWidth
							variant='standard'
							{...register("skills")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='Draw attention to the association and its goals'
							label='اوجه الاهتمام بالجمعية و أهدافها'
							fullWidth
							variant='standard'
							{...register("drawAttention")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='The most important activities of the association that you would like to contribute to'
							label='أهم أنشطة الجمعية التي تود المساهمة فيها'
							fullWidth
							variant='standard'
							{...register("activities")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='Have you done volunteer work before/what is it?'
							label='هل قمت بأعمال تطوعية من قبل/ و ما هو؟'
							fullWidth
							variant='standard'
							{...register("doneVolunteerbefore")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='How much time do you have to ?'
							label='ما هو الوقت المتاح لديك للتطوع به'
							fullWidth
							variant='standard'
							{...register("volunteerTime")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='The reason to volunteer'
							label='سبب التطوع'
							fullWidth
							variant='standard'
							{...register("reasonForVolunteer")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='Lighting'
							label='الامضاء'
							fullWidth
							variant='standard'
							{...register("lighting")}
							disabled
						/>
					</Grid>
				</Grid>
			</form>
		</Container>
	);
};

export default SingleFormSubmission;
