import { Container, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SwiperCore, { EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import ReactPlayer from "react-player";
import Pagination from "@mui/material/Pagination";
SwiperCore.use([EffectCoverflow, Pagination]);

const AlMustafa = () => {
	const [data, setData] = React.useState([]);
	useEffect(() => {
		fetch(
			`https://sawa4leb.bcard.a2hosted.com/alMustafaCollectionImages`,
		)
			.then((res) => res.json())
			.then((data) => setData(data));
	}, []);

	const [page, setPage] = useState(0);
	const [datas, setDatas] = React.useState([]);
	useEffect(() => {
		fetch(
			`https://sawa4leb.bcard.a2hosted.com/alMustafaCollectionVideos`,
		)
			.then((res) => res.json())
			.then((data) => setDatas(data));
	}, []);
	const [text, setText] = React.useState([]);
	const type = "alMustafaCollectionDetails";
	useEffect(() => {
		fetch(
			`https://sawa4leb.bcard.a2hosted.com/alMustafaCollectionDetails/${type}`,
		)
			.then((res) => res.json())
			.then((data) => setText(data));
	}, []);

	return (
		<Container sx={{ py: 3 }}>
			<Typography
				className='textColor'
				sx={{ mb: 3, fontWeight: 900 }}
				variant='h4'
				component='div'
				gutterBottom>
				أفران المصطفى
				<Divider className='hr' />
			</Typography>
			<Swiper
				effect={"coverflow"}
				grabCursor={true}
				centeredSlides={true}
				slidesPerView={"auto"}
				initialSlide={2}
				coverflowEffect={{
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier: 1,
					slideShadows: false,
				}}
				pagination={true}
				className='mySwiper'>
				{data?.map((a) => (
					<SwiperSlide key={a?._id}>
						<img src={a?.imageLink2} alt='' />
					</SwiperSlide>
				))}
			</Swiper>
			<Grid container spacing={2} sx={{ my: 2 }}>
				<Grid item md={9} xs={12} sx={{ mx: "auto" }}>
					<ReactPlayer
						width='100%'
						height='100%'
						url={datas[page]?.videoLink}
						controls
					/>
				</Grid>
				<Grid item md={12} xs={12} sx={{ mx: "auto" }}>
					<Typography
						className='textColor'
						sx={{ fontWeight: 900 }}
						variant='h5'
						component='div'>
						{datas[page]?.videoTitle}
					</Typography>
				</Grid>
				<Grid className='pagination' item md={9} xs={12} sx={{ mx: "auto" }}>
					<Pagination
						count={datas?.length}
						onChange={(e, value) => setPage(value - 1)}
						variant='outlined'
						shape='rounded'
					/>
				</Grid>
			</Grid>

			<Typography
				className='textColor'
				sx={{ my: 3 }}
				variant='h6'
				component='div'
				gutterBottom>
				{text?.details}
			</Typography>
		</Container>
	);
};

export default AlMustafa;
