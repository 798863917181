import {
	Box,
	Button,
	ButtonGroup,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { reactLocalStorage } from "reactjs-localstorage";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Courses = () => {
	const getId = reactLocalStorage.getObject("selectedRowCourses")?.selected;
	const leveled = reactLocalStorage.getObject("levelCourses")?.level;
	const viewed = (id) => {
		reactLocalStorage.setObject("selectedRowCourses", { selected: id });
	};
	const [level, setLevel] = useState(leveled || `Level-1`);
	const [forms, setForms] = useState([]);
	useEffect(() => {
		fetch(
			`https://sawa4leb.bcard.a2hosted.com/courseslevel/?importance=${level}`,
		)
			.then((res) => res.json())
			.then((data) => setForms(data.reverse()));
	}, [level]);
	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(
						`https://sawa4leb.bcard.a2hosted.com/courses/${id}`,
					)
					.then(function (response) {
						Swal.fire("Deleted!", "That form has been deleted.", "success");
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	let count = 1;

	const headers = [
		{ label: "ID", key: "id" },
		{ label: "Locations", key: "locations" },
		{ label: "إسم الطالب", key: "a" },
		{ label: "إسم الأب", key: "b" },
		{ label: "إسم العائلة", key: "c" },
		{ label: "تاريخ الولادة", key: "d" },
		{ label: "إسم الأم", key: "e" },
		{ label: "رقم القيد", key: "f" },
		{ label: "إسم المدرسة", key: "g" },
		{ label: "الصف", key: "h" },
		{ label: "بالمستوى التعليميالتفصيل", key: "i" },
		{ label: "هل يوجد حالة صحية للتلميذ", key: "j" },
		{ label: "هل يوجد حساسية", key: "k" },
		{ label: "هل يوجد مرض مزمن", key: "l" },
		{ label: "ما هو العنوان السكن", key: "m" },
		{ label: "رقم الهاتف", key: "n" },
		{ label: "البريد الإلكتروني", key: "o" },
		{ label: "Image Link 1", key: "imageLink1" },
		{ label: "Image Link 2", key: "imageLink2" },
		{ label: "Importance Level", key: "importance" },
	];
	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }}>
			<Grid>
				<Typography
					className='textColor'
					sx={{ mb: 3, fontWeight: "bold" }}
					variant='h4'
					component='div'
					gutterBottom>
					All Student Requests
				</Typography>
				<Box sx={{ mb: 4 }}>
					<Typography
						sx={{ fontWeight: "bold" }}
						variant='h6'
						gutterBottom
						className='textColor'>
						Sort By Importance Level
					</Typography>
					<ButtonGroup
						variant='contained'
						aria-label='outlined primary button group'>
						{level === `Level-1` ? (
							<button
								disabled
								style={{ backgroundColor: "red", color: "white" }}>
								High
							</button>
						) : (
							<Button
								onClick={() => {
									setLevel(`Level-1`);
									reactLocalStorage.setObject("levelCourses", {
										level: `Level-1`,
									});
								}}>
								High
							</Button>
						)}
						{level === `Level-2` ? (
							<button
								disabled
								style={{ backgroundColor: "red", color: "white" }}>
								Medium
							</button>
						) : (
							<Button
								onClick={() => {
									setLevel(`Level-2`);
									reactLocalStorage.setObject("levelCourses", {
										level: `Level-2`,
									});
								}}>
								Medium
							</Button>
						)}
						{level === `Level-3` ? (
							<button
								disabled
								style={{ backgroundColor: "red", color: "white" }}>
								Low
							</button>
						) : (
							<Button
								onClick={() => {
									setLevel(`Level-3`);
									reactLocalStorage.setObject("levelCourses", {
										level: `Level-3`,
									});
								}}>
								Low
							</Button>
						)}
						{level === `N/A` ? (
							<button
								disabled
								style={{ backgroundColor: "red", color: "white" }}>
								N/A
							</button>
						) : (
							<Button
								onClick={() => {
									setLevel(`N/A`);
									reactLocalStorage.setObject("levelCourses", {
										level: `N/A`,
									});
								}}>
								N/A
							</Button>
						)}
					</ButtonGroup>
				</Box>
				<Button
					className='csvBtn button border'
					sx={{
						fontWeight: "bold",
						border: "2px solid white !important",
						backgroundColor: "transparent",
						borderRadius: "25px",
						mb: 2.5,
					}}
					variant='contained'>
					<FileDownloadIcon />
					<CSVLink
						filename={"All Student Requests.csv"}
						data={forms}
						headers={headers}
						style={{
							textDecoration: "none",
							color: "white",
							fontWeight: "bold",
							marginLeft: 5,
						}}>
						Download Forms
					</CSVLink>
				</Button>
				<Paper
					className='container'
					sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
					<Table size='small' aria-label='a dense table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>إسم الطالب </TableCell>
								<TableCell align='center'>Action</TableCell>
							</TableRow>
						</TableHead>
						{forms?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{forms.map((form) => (
									<TableRow
										className={form?._id === getId ? "selectedRow" : ""}
										key={form?._id}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										<TableCell align='left'>{count++}</TableCell>
										<TableCell align='left'>{form?.a}</TableCell>
										<TableCell align='center'>
											<Link
												onClick={() => viewed(form?._id)}
												to={`/dashboard/allcourses/${form?._id}`}
												style={{ textDecoration: "none" }}>
												<Button
													className='button border'
													sx={{
														fontWeight: "bold",
														border: "2px solid white !important",
														backgroundColor: "transparent",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<RemoveRedEyeIcon />
												</Button>
											</Link>

											<Button
												className='button border'
												onClick={() => handleDelete(form?._id)}
												sx={{
													fontWeight: "bold",
													border: "2px solid white !important",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='center'>N/A</TableCell>
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</Grid>
		</Container>
	);
};

export default Courses;
