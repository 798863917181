import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Box, Container, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ReactToPrint from "react-to-print";

const SingleCourses = () => {
	let ref = React.createRef();
	const { id } = useParams();
	const [data, setData] = useState();
	const { register, reset } = useForm({
		defaultValues: {
			a: "",
			b: "",
			c: "",
			d: "",
			e: "",
			f: "",
			g: "",
			h: "",
			i: "",
			j: "",
			k: "",
			l: "",
			m: "",
			n: "",
			o: "",
		},
	});
	useEffect(() => {
		axios
			.get(`https://sawa4leb.bcard.a2hosted.com/courses/${id}`)
			.then((res) => {
				reset(res.data);
				setData(res.data);
			});
	}, [id, reset]);
	const level1 = () => {
		const data = {
			importance: "Level-1",
		};
		axios
			.put(
				`https://sawa4leb.bcard.a2hosted.com/courseslevel/${id}`,
				data,
			)
			.then(function (response) {
				Swal.fire({
					icon: "success",
					title: "Importance level changed Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const level2 = () => {
		const data = {
			importance: "Level-2",
		};
		axios
			.put(
				`https://sawa4leb.bcard.a2hosted.com/courseslevel/${id}`,
				data,
			)
			.then(function (response) {
				Swal.fire({
					icon: "success",
					title: "Importance level changed Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const level3 = () => {
		const data = {
			importance: "Level-3",
		};
		axios
			.put(
				`https://sawa4leb.bcard.a2hosted.com/courseslevel/${id}`,
				data,
			)
			.then(function (response) {
				Swal.fire({
					icon: "success",
					title: "Importance level changed Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	return (
		<Container sx={{ py: 1 }} dir='rtl'>
			<Button
				onClick={handleOpen}
				className=' button border'
				sx={{
					fontWeight: "bold",
					border: "2px solid",
					backgroundColor: "transparent",
					borderRadius: "25px",
					width: "180px",
				}}>
				Open PDF
				<OpenInFullIcon sx={{ mr: 1 }} />
			</Button>
			<Modal
				dir='rtl'
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: "90vw",
						height: "90vh",
						bgcolor: "white",
						border: "2px solid #000",
						boxShadow: 24,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<ReactToPrint
						onAfterPrint={() => {
							setOpen(false);
							Swal.fire({
								icon: "success",
								title: "PDF Saved",
								showConfirmButton: true,
								timer: 2700,
							});
						}}
						trigger={() => (
							<Button
								className='csvBtn button border'
								sx={{
									fontWeight: "bold",
									border: "2px solid white !important",
									backgroundColor: "transparent",
									borderRadius: "25px",
									width: "180px",
									my: 1,
								}}
								variant='contained'>
								Save PDF
								<FileDownloadIcon sx={{ mr: 1 }} />
							</Button>
						)}
						content={() => ref}
					/>
					<Box
						sx={{
							overflow: "scroll !important",
							width: "90vw",
							height: "90vh",
							bgcolor: "white",
							border: "2px solid #000",
							boxShadow: 24,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<div className='formPagePdf' ref={(el) => (ref = el)}>
							<div className='page' dir='rtl'>
								<Grid
									container
									spacing={3}
									dir='ltr'
									sx={{ justifyContent: "flex-end", alignItems: "center" }}>
									<Grid item>
										<Typography variant='h4' sx={{ fontWeight: "bold" }}>
											جمعية المرأة السفيرة
										</Typography>
										<Typography>
											العمل التطوعي هو حاجة إجتماعية تحقق الترابط، التآلف و
											التآخي بين أفرد المجتمع
										</Typography>
									</Grid>

									<Grid item>
										<img
											style={{ width: "70px", height: "70px" }}
											src='https://res.cloudinary.com/dqsndznxf/image/upload/v1640213898/evahassoun/uqmromk3wlp4kf0eznxb.png'
											alt=''
										/>
									</Grid>
								</Grid>
								<hr
									style={{
										border: "1.5px solid",
										margin: "0.5rem 1rem 0 1rem ",
									}}
								/>
								<Typography
									variant='h4'
									gutterBottom
									sx={{
										textAlign: "center",
										fontWeight: "bold",
										mt: 1.5,
									}}>
									طلب تسجيل طالب في الدورة العلمية
								</Typography>
								<div>
									<Box className='formDataImg'>
										<div className='inputField'>
											<img className='pdfImg' src={data?.imageLink1} alt='' />
										</div>
										<div className='inputField'>
											<img className='pdfImg' src={data?.imageLink2} alt='' />
										</div>
									</Box>
									<Box className='formData' sx={{ mt: 1 }}>
										<div className='inputField'>
											<span>إسم الطالب : </span>
											<span className='p'>
												<span>{data?.a}</span>
											</span>
										</div>
										<div className='inputField'>
											<span>إسم الأب : </span>
											<span className='p'>
												<span>{data?.b} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>إسم العائلة : </span>
											<span className='p'>
												<span>{data?.c} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>تاريخ الولادة : </span>
											<span className='p'>
												<span>{data?.d} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>إسم الأم : </span>
											<span className='p'>
												<span>{data?.e} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>رقم القيد : </span>
											<span className='p'>
												<span>{data?.f} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>إسم المدرسة : </span>
											<span className='p'>
												<span>{data?.g} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>الصف : </span>
											<span className='p'>
												<span>{data?.h} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>بالمستوى التعليميالتفصيل : </span>
											<span className='p'>
												<span>{data?.i} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>هل يوجد حالة صحية للتلميذ : </span>
											<span className='p'>
												<span>{data?.j} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>هل يوجد حساسية : </span>
											<span className='p'>
												<span>{data?.k} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>هل يوجد مرض مزمن : </span>
											<span className='p'>
												<span>{data?.l} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>ما هو العنوان السكن : </span>
											<span className='p'>
												<span>{data?.m} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>رقم الهاتف : </span>
											<span className='p'>
												<span>{data?.n} </span>
											</span>
										</div>
										<div className='inputField'>
											<span>موقعك : </span>
											<span className='p'>
												<span>{data?.locations} </span>
											</span>
										</div>
										<div className='inputField'></div>
										<div className='inputField'>
											<span>البريد الإلكتروني : </span>
											<span className='p'>
												{" "}
												<span>{data?.o}</span>
											</span>
										</div>
									</Box>
								</div>
							</div>
						</div>
					</Box>
				</Box>
			</Modal>

			<Box sx={{ mb: 2 }}>
				<Typography
					sx={{ fontWeight: "bold" }}
					variant='h6'
					gutterBottom
					className='textColor'>
					Set Importance Level
				</Typography>

				<ButtonGroup
					variant='contained'
					aria-label='outlined primary button group'>
					{data?.importance === "Level-1" ? (
						<button disabled style={{ backgroundColor: "red", color: "white" }}>
							High
						</button>
					) : (
						<Button onClick={() => level1()}>High</Button>
					)}
					{data?.importance === "Level-2" ? (
						<button disabled style={{ backgroundColor: "red", color: "white" }}>
							Medium
						</button>
					) : (
						<Button onClick={() => level2()}>Medium</Button>
					)}
					{data?.importance === "Level-3" ? (
						<button disabled style={{ backgroundColor: "red", color: "white" }}>
							Low
						</button>
					) : (
						<Button onClick={() => level3()}>Low</Button>
					)}
				</ButtonGroup>
			</Box>
			<Typography variant='h4' gutterBottom className='textColor'>
				{data?.a}
			</Typography>
			<Typography variant='h6' gutterBottom className='textColor'>
				Location : {data?.locations || "N/A"}
			</Typography>
			<form method='post'>
				<Grid container spacing={3}>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							display='flex'
							flexDirection='column'
							alignItems='center'
							sx={{ my: 3, mx: "auto" }}>
							<img
								src={data?.imageLink1}
								className='borderColor'
								style={{ width: "250px", border: "5px solid" }}
								alt=''
							/>
						</Box>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Box
							display='flex'
							flexDirection='column'
							alignItems='center'
							sx={{ my: 3, mx: "auto" }}>
							<img
								src={data?.imageLink2}
								className='borderColor'
								style={{ width: "250px", border: "5px solid" }}
								alt=''
							/>
						</Box>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='a'
							label='إسم الطالب'
							fullWidth
							variant='standard'
							{...register("a")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='b'
							label='إسم الأب'
							fullWidth
							variant='standard'
							{...register("b")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='c'
							label='إسم العائلة'
							fullWidth
							variant='standard'
							{...register("c")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='d'
							label='تاريخ الولادة'
							fullWidth
							variant='standard'
							{...register("d")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='e'
							label='إسم الأم'
							fullWidth
							variant='standard'
							{...register("e")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='f'
							label='رقم القيد'
							fullWidth
							variant='standard'
							{...register("f")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='g'
							label='إسم المدرسة'
							fullWidth
							variant='standard'
							{...register("g")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='h'
							label='الصف'
							fullWidth
							variant='standard'
							{...register("h")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='i'
							label='بالمستوى التعليميالتفصيل'
							fullWidth
							variant='standard'
							{...register("i")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='j'
							label=' هل يوجد حالة صحية للتلميذ'
							fullWidth
							variant='standard'
							{...register("j")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='k'
							label=' هل يوجد حساسية'
							fullWidth
							variant='standard'
							{...register("k")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='l'
							label=' هل يوجد مرض مزمن'
							fullWidth
							variant='standard'
							{...register("l")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='m'
							label='ما هو العنوان السكن'
							fullWidth
							variant='standard'
							{...register("m")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='n'
							label='رقم الهاتف'
							fullWidth
							variant='standard'
							{...register("n")}
							disabled
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							name='o'
							label='البريد الإلكتروني'
							fullWidth
							variant='standard'
							{...register("o")}
							disabled
						/>
					</Grid>
				</Grid>
			</form>
		</Container>
	);
};

export default SingleCourses;
