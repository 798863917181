import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const Banner = () => {
	const [banner, setBanner] = React.useState([]);
	React.useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/banner/banner`)
			.then((res) => res.json())
			.then((data) => setBanner(data));
	}, []);
	const [profile, setProfile] = React.useState([]);
	React.useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/profile/profile`)
			.then((res) => res.json())
			.then((data) => setProfile(data));
	}, []);
	const [headline, setHeadline] = React.useState([]);
	React.useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/headline/headline`)
			.then((res) => res.json())
			.then((data) => setHeadline(data));
	}, []);
	return (
		<div>
			<Card
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					boxShadow: 0,
					position: "relative",
					backgroundColor: "transparent",
				}}>
				<div
					className='bannerPhoto'
					style={{
						position: "relative",
						backgroundSize: "cover",
						backgroundImage: `url(${banner?.imageLink2})`,
						backgroundPosition: "50% 25%",
						clipPath: "polygon(50% 100%, 100% 85%, 100% 0, 0 0, 0 85%)",
						width: "100%",
					}}></div>
				<Box sx={{ height: 220, width: 220, mt: -13, position: "relative" }}>
					<CardMedia
						sx={{ borderRadius: "50%" }}
						style={{
							boxShadow:
								"-1px 9px 27px 2px rgb(236 0 133 / 56%) -1px 9px 27px 2px",
						}}
						component='img'
						alt=''
						image={profile?.imageLink2}
					/>
				</Box>
				<CardContent>
					<Typography
						className='textColor'
						sx={{ mt: 3, mb: 1, fontWeight: 700 }}
						variant='h4'
						component='div'
						gutterBottom>
						{headline?.title}
					</Typography>
					<Typography
						className='textColor'
						sx={{ mb: 2, fontWeight: 700 }}
						variant='body'
						component='div'
						gutterBottom>
						{headline?.subtitle}
					</Typography>
				</CardContent>
			</Card>
		</div>
	);
};

export default Banner;
