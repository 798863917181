import { Container, Fab, Grid, Typography } from "@mui/material";
import React from "react";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WhatsappOutlinedIcon from "@mui/icons-material/WhatsappOutlined";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import SmsIcon from "@mui/icons-material/Sms";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import logo from "./logo.png";

const Buttons = () => {
	const [data, setData] = React.useState([]);
	React.useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/links/links`)
			.then((res) => res.json())
			.then((data) => setData(data));
	}, []);
	return (
		<Container>
			<Grid container spacing={2} className='icons'>
				{data?.facebook !== "na" && (
					<Grid
						item
						xs
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Fab
							onClick={() => window.open(`${data?.facebook}`, "_blank")}
							color='primary'
							aria-label='add'>
							<FacebookOutlinedIcon />
						</Fab>
						<Typography
							className='textColor'
							sx={{ mt: 1, fontWeight: "bold" }}
							variant='button'
							display='block'
							gutterBottom>
							Facebook
						</Typography>
					</Grid>
				)}
				{data?.email !== "na" && (
					<Grid
						item
						xs
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Fab
							onClick={() => window.open(`mailto:${data?.email}`, "_blank")}
							color='primary'
							aria-label='add'>
							<EmailOutlinedIcon />
						</Fab>
						<Typography
							className='textColor'
							sx={{ mt: 1, fontWeight: "bold" }}
							variant='button'
							display='block'
							gutterBottom>
							Email
						</Typography>
					</Grid>
				)}
				{data?.whatsapp !== "na" && (
					<Grid
						item
						xs
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Fab
							onClick={() =>
								window.open(
									`https://api.whatsapp.com/send?phone=${data?.whatsapp}`,
									"_blank",
								)
							}
							color='primary'
							aria-label='add'>
							<WhatsappOutlinedIcon />
						</Fab>
						<Typography
							className='textColor'
							sx={{ mt: 1, fontWeight: "bold" }}
							variant='button'
							display='block'
							gutterBottom>
							Whatsapp
						</Typography>
					</Grid>
				)}
				{data?.youtube !== "na" && (
					<Grid
						item
						xs
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Fab
							onClick={() => window.open(`${data?.youtube}`, "_blank")}
							color='primary'
							aria-label='add'>
							<SubscriptionsOutlinedIcon />
						</Fab>
						<Typography
							className='textColor'
							sx={{ mt: 1, fontWeight: "bold" }}
							variant='button'
							display='block'
							gutterBottom>
							Youtube
						</Typography>
					</Grid>
				)}
				{data?.sms !== "na" && (
					<Grid
						item
						xs
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Fab
							onClick={() => window.open(`sms:${data?.sms}`, "_blank")}
							color='primary'
							aria-label='add'>
							<SmsIcon />
						</Fab>
						<Typography
							className='textColor'
							sx={{ mt: 1, fontWeight: "bold" }}
							variant='button'
							display='block'
							gutterBottom>
							SMS
						</Typography>
					</Grid>
				)}
				{data?.twitter !== "na" && (
					<Grid
						item
						xs
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Fab
							onClick={() => window.open(`${data?.twitter}`, "_blank")}
							color='primary'
							aria-label='add'>
							<TwitterIcon />
						</Fab>
						<Typography
							className='textColor'
							sx={{ mt: 1, fontWeight: "bold" }}
							variant='button'
							display='block'
							gutterBottom>
							Twitter
						</Typography>
					</Grid>
				)}
				{data?.instagram !== "na" && (
					<Grid
						item
						xs
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Fab
							onClick={() => window.open(`${data?.instagram}`, "_blank")}
							color='primary'
							aria-label='add'>
							<InstagramIcon />
						</Fab>
						<Typography
							className='textColor'
							sx={{ mt: 1, fontWeight: "bold" }}
							variant='button'
							display='block'
							gutterBottom>
							Instagram
						</Typography>
					</Grid>
				)}
				<Grid
					item
					xs
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Link to='/form' style={{ textDecoration: "none" }}>
						<Fab color='primary' aria-label='add'>
							<VolunteerActivismIcon />
						</Fab>
						<Typography
							className='textColor'
							sx={{ mt: 1, fontWeight: "bold" }}
							variant='button'
							display='block'
							gutterBottom>
							للتطوع معنا
						</Typography>
					</Link>
				</Grid>
				<Grid
					item
					xs
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Link to='/almustafa' style={{ textDecoration: "none" }}>
						<img
							className='borderColor'
							style={{
								width: "56px",
								height: "56px",
								border: "1px solid",
								borderRadius: "50%",
							}}
							src={logo}
							alt=''
						/>
						<Typography
							className='textColor'
							sx={{ mt: 1, fontWeight: "bold" }}
							variant='button'
							display='block'
							gutterBottom>
							أفران المصطفى
						</Typography>
					</Link>
				</Grid>
				<Grid
					item
					xs
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Link to='/heartonheart' style={{ textDecoration: "none" }}>
						<Fab color='primary' aria-label='add'>
							<VolunteerActivismIcon />
						</Fab>
						<Typography
							className='textColor'
							sx={{ mt: 1, fontWeight: "bold" }}
							variant='button'
							display='block'
							gutterBottom>
							قلب على القلب
						</Typography>
					</Link>
				</Grid>
				<Grid item md={12} xs={12} className='donate'>
					<Link to='/donate' style={{ textDecoration: "none" }}>
						<Fab
							variant='extended'
							sx={{
								px: 4,
								fontWeight: "bold",
							}}>
							<VolunteerActivismIcon sx={{ mr: 1.5 }} />
							تبرع هنا
						</Fab>
					</Link>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Buttons;
