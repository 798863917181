import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AddReview from "./Pages/AddReview/AddReview";
import AllMails from "./Pages/Dashboard/AllMails/AllMails";
import AllReviews from "./Pages/Dashboard/AllReviews/AllReviews";
import BannerPhoto from "./Pages/Dashboard/BannerPhoto/BannerPhoto";
import ButtonsLink from "./Pages/Dashboard/ButtonsLink/ButtonsLink";
import ColorChange from "./Pages/Dashboard/ColorChange/ColorChange";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Details from "./Pages/Dashboard/Details/Details";
import FormSubmissions from "./Pages/Dashboard/FormSubmissions/FormSubmissions";
import FormSubmissions2 from "./Pages/Dashboard/FormSubmissions2/FormSubmissions2";
import Galleryphoto from "./Pages/Dashboard/Galleryphoto/Galleryphoto";
import Headline from "./Pages/Dashboard/Headline/Headline";
import ProfilePhoto from "./Pages/Dashboard/ProfilePhoto/ProfilePhoto";
import Form from "./Pages/Form/Form";
import Form2 from "./Pages/Form2/Form2";
import HomepageMain from "./Pages/Homepage/HomepageMain";
/* import HashLoader from "react-spinners/HashLoader"; */
import SingleFormSubmission from "./Pages/Dashboard/FormSubmissions/SingleFormSubmission";
import SingleForm2Submission from "./Pages/Dashboard/FormSubmissions2/SingleForm2Submission";
import HeartOnHeart from "./Pages/HeartOnHeart/HeartOnHeart";
import Gallery2photo from "./Pages/Dashboard/Gallery2photo/Gallery2photo";
import Donate from "./Pages/Donate/Donate";
import Donations from "./Pages/Dashboard/Donations/Donations";
import Login from "./Pages/Login/Login";
import AdminRoute from "./AdminRoute/AdminRoute";
import MailCause from "./Pages/Dashboard/MailCause/MailCause";
import SingleMail from "./Pages/Dashboard/AllMails/SingleMail";
import Course from "./Pages/Course/Course";
import SingleCourses from "./Pages/Dashboard/Courses/SingleCourses";
import Courses from "./Pages/Dashboard/Courses/Courses";
import { CardMedia } from "@mui/material";
import NotFound from "./Pages/NotFound";
import AlMustafa from "./Pages/AlMustafa/AlMustafa";
import AlMustafaControl from "./Pages/Dashboard/AlMustafaControl/AlMustafaControl";

function App() {
	const [colors, setColors] = React.useState(null);
	React.useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/color/color`)
			.then((res) => res.json())
			.then((data) => setColors(data));
	}, []);

	React.useEffect(() => {
		let timerId;

		// Set a timer to refresh the page if data is not received within 5 seconds
		timerId = setTimeout(() => {
			window.location.reload();
		}, 5000);

		// Clear the timer when data is received
		if (colors) {
			clearTimeout(timerId);
		}

		// Cleanup: Clear the timer when the component unmounts
		return () => {
			clearTimeout(timerId);
		};
	}, [colors]);

	/* 	React.useEffect(() => {
		const pingServer = async () => {
			try {
				const response = await fetch(
					`https://sawa4leb.bcard.a2hosted.com/keepalive`,
				);
				if (response.ok) {
					console.log("Server is awake!");
				} else {
					console.warn("Server is not responding as expected.");
				}
			} catch (error) {
				console.error("Error pinging server:", error);
			}
		};

		const pingInterval = setInterval(pingServer, 10000);

		// Clear the interval when the component unmounts
		return () => clearInterval(pingInterval);
	}, []); */
	document.documentElement.style.setProperty("--mainColor", `${colors?.color}`);
	return (
		<div className='App'>
			{colors?.color ? (
				<BrowserRouter>
					<Routes>
						<Route path='/' element={<HomepageMain />} />
						<Route path='/login' element={<Login />} />
						<Route path='/donate' element={<Donate />} />
						<Route path='/heartonheart' element={<HeartOnHeart />} />
						<Route path='/form' element={<Form />} />
						<Route path='/form2' element={<Form2 />} />
						<Route path='/course' element={<Course />} />
						<Route path='/addreviews' element={<AddReview />} />
						<Route path='/almustafa' element={<AlMustafa />} />
						<Route path='*' element={<NotFound />} />
						<Route
							exact
							path='/dashboard'
							element={
								<AdminRoute>
									<Dashboard />
								</AdminRoute>
							}>
							<Route path='/dashboard' element={<BannerPhoto />} />
							<Route
								path='/dashboard/profilephoto'
								element={<ProfilePhoto />}
							/>
							<Route path='/dashboard/donates' element={<Donations />} />
							<Route
								path='/dashboard/almustafacontrol'
								element={<AlMustafaControl />}
							/>
							<Route path='/dashboard/headline' element={<Headline />} />
							<Route path='/dashboard/buttonslink' element={<ButtonsLink />} />
							<Route path='/dashboard/colorchange' element={<ColorChange />} />
							<Route path='/dashboard/details' element={<Details />} />
							<Route
								path='/dashboard/galleryphoto'
								element={<Galleryphoto />}
							/>
							<Route
								path='/dashboard/gallery2photo'
								element={<Gallery2photo />}
							/>
							<Route path='/dashboard/allreviews' element={<AllReviews />} />
							<Route path='/dashboard/allcourses' element={<Courses />} />
							<Route path='/dashboard/allemails' element={<AllMails />} />
							<Route path='/dashboard/mailcause' element={<MailCause />} />
							<Route
								path='/dashboard/formsubmissions'
								element={<FormSubmissions />}
							/>
							<Route path='/dashboard/mails/:id' element={<SingleMail />} />
							<Route
								path='/dashboard/allcourses/:id'
								element={<SingleCourses />}
							/>
							<Route
								path='/dashboard/formsubmissions/:id'
								element={<SingleFormSubmission />}
							/>
							<Route
								path='/dashboard/formsubmissions2'
								element={<FormSubmissions2 />}
							/>
							<Route
								path='/dashboard/form2submissions/:id'
								element={<SingleForm2Submission />}
							/>
						</Route>
					</Routes>
				</BrowserRouter>
			) : (
				<div
					style={{
						position: "absolute",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					{/* <HashLoader size={100} color={"#35D5B6"} /> */}
					<CardMedia
						image='/loading.png'
						alt='loading logo'
						className='loading-logo'
						sx={{
							width: 170,
							height: 170,
						}}
					/>
				</div>
			)}
		</div>
	);
}

export default App;
