import {
	Button,
	Container,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
const AddReview = () => {
	const navigate = useNavigate();
	const [submitting, setSubmitting] = useState(false);
	const [imageLink2, setImageLink2] = useState(null);
	const { register, handleSubmit, reset } = useForm();
	const onSubmit = ({ userName, userReview }) => {
		const data = {
			userName,
			userReview,
			userPhoto:
				imageLink2 ||
				"https://res.cloudinary.com/dqsndznxf/image/upload/v1655662084/default/user_sovhsi.jpg",
		};
		setSubmitting(true);
		axios
			.post(`https://sawa4leb.bcard.a2hosted.com/review`, data)
			.then(function (response) {
				Swal.fire({
					icon: "success",
					title: "Your Review Successfully Added",
					showConfirmButton: false,
					timer: 1500,
				}).then(function () {
					reset();
					navigate("/");
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const [loading, setLoading] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "evahassoun");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/dqsndznxf/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});
	return (
		<Container sx={{ mt: { md: 0, xs: 7 } }}>
			<Grid
				className='color-text'
				container
				direction='column'
				alignItems='center'
				justifyContent='center'
				sx={{ minHeight: { md: "100vh", xs: "90vh" } }}>
				<Typography
					className='textColor'
					sx={{ mb: 4, fontWeight: 900 }}
					variant='h3'
					component='div'
					gutterBottom
					data-aos='fade-right'>
					ADD REVIEW
					<Typography
						variant='caption'
						display='block'
						gutterBottom
						sx={{ color: "red" }}>
						Your posts will appear publicly with your profile name and picture.
						Your posts will appear across the web.
					</Typography>
				</Typography>

				<Grid container spacing={2}>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						{!submitting ? (
							<form data-aos='fade-left' onSubmit={handleSubmit(onSubmit)}>
								<Box
									display='flex'
									flexDirection='column'
									alignItems='center'
									sx={{ mt: 3, mb: 1, mx: "auto" }}>
									<label
										className='bgColor'
										htmlFor='icon-button-file'
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											margin: "0 9px",
											borderRadius: 5,
										}}>
										<Input
											accept='image/*'
											id='icon-button-file'
											type='file'
											onChange={uploadImage}
										/>
										<Typography
											sx={{ m: 2, color: "white" }}
											variant='h6'
											component='div'
											gutterBottom>
											Upload Your Picture*
										</Typography>
										<IconButton
											color='primary'
											aria-label='upload picture'
											component='span'>
											<AccountCircleIcon
												fontSize='large'
												sx={{ fontWeight: "bold" }}
											/>
										</IconButton>
									</label>

									{loading ? (
										<Box sx={{ my: 2 }}>
											<CircularProgress className='textColor' />
										</Box>
									) : (
										<img src={imageLink2} style={{ width: "300px" }} alt='' />
									)}
								</Box>
								<TextField
									required
									sx={{ width: "100%", mb: 2 }}
									id='"outlined-multiline-flexible'
									label='Name'
									{...register("userName", { required: true })}
								/>
								<TextField
									required
									sx={{ width: "100%", mb: 2 }}
									id='"outlined-multiline-flexible'
									label='Share Your Review'
									multiline
									rows={4}
									{...register("userReview", { required: true })}
								/>
								<Button
									type='submit'
									variant='contained'
									className='button border'
									sx={{
										width: "100%",
										mb: 2,
										px: 3,
										fontWeight: "bold",
										border: "2px solid",
										backgroundColor: "transparent",
										borderRadius: "25px",
									}}>
									POST REVIEW
								</Button>
							</form>
						) : (
							<Box sx={{ my: 2 }}>
								<CircularProgress className='textColor' sx={{ mx: 0.5 }} />
							</Box>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};

export default AddReview;
