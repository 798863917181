import {
	Box,
	Button,
	ButtonGroup,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { reactLocalStorage } from "reactjs-localstorage";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const FormSubmissions2 = () => {
	const getId = reactLocalStorage.getObject("selectedRowHeart")?.selected;
	const leveled = reactLocalStorage.getObject("levelHeart")?.level;
	const viewed = (id) => {
		reactLocalStorage.setObject("selectedRowHeart", { selected: id });
	};
	const [level, setLevel] = useState(leveled || `VVI`);
	const [forms, setForms] = useState([]);
	useEffect(() => {
		fetch(
			`https://sawa4leb.bcard.a2hosted.com/forms2level/?importance=${level}`,
		)
			.then((res) => res.json())
			.then((data) => setForms(data.reverse()));
	}, [level]);
	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(
						`https://sawa4leb.bcard.a2hosted.com/forms2/${id}`,
					)
					.then(function (response) {
						Swal.fire("Deleted!", "That form has been deleted.", "success");
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	let count = 1;

	const headers = [
		{ label: "ID", key: "_id" },
		{ label: "الإسم", key: "a" },
		{ label: "الشهرة", key: "b" },
		{ label: "إسم الأب", key: "c" },
		{ label: "إسم الأم", key: "d" },
		{ label: "الجنس", key: "e" },
		{ label: "القضاء", key: "f" },
		{ label: "القضاء", key: "g" },
		{ label: "المدينة أو القرية", key: "h" },
		{ label: "عنوان السكن بالتفصيل", key: "i" },
		{ label: "رقم الهاتف", key: "j" },
		{ label: "رقم السجل", key: "k" },
		{ label: "مكان السجل", key: "l" },
		{ label: "الوضع الاجتماعي", key: "m" },
		{ label: "عدد أفراد الأسرة", key: "n" },
		{
			label: "تحديد الحالات المرضية الموجودة في الأسرة/ذوي احتياجات خاصة؟",
			key: "o",
		},
		{ label: "هل لديكم بطاقة شؤون؟", key: "p" },
		{
			label: "هل أحد أفراد الأسرة موظف في القطاع العام/أجهزة أمنية؟",
			key: "q",
		},
		{ label: "Image", key: "imageLink" },
		{ label: "Importance Level", key: "importance" },
	];

	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }}>
			<Grid>
				<Typography
					className='textColor'
					sx={{ mb: 3, fontWeight: "bold" }}
					variant='h4'
					component='div'
					gutterBottom>
					All Heart On Heart Forms
				</Typography>
				<Box sx={{ mb: 4 }}>
					<Typography
						sx={{ fontWeight: "bold" }}
						variant='h6'
						gutterBottom
						className='textColor'>
						Sort By Importance Level
					</Typography>
					<ButtonGroup
						variant='contained'
						aria-label='outlined primary button group'>
						{level === `VVI` ? (
							<button
								disabled
								style={{
									backgroundColor: "red",
									color: "white",
									padding: "0 15px",
									fontWeight: "bold",
								}}>
								ايتام
							</button>
						) : (
							<Button
								onClick={() => {
									setLevel(`VVI`);
									reactLocalStorage.setObject("levelHeart", {
										level: `VVI`,
									});
								}}>
								ايتام
							</Button>
						)}
						{level === `Level-1` ? (
							<button
								disabled
								style={{ backgroundColor: "red", color: "white" }}>
								High
							</button>
						) : (
							<Button
								onClick={() => {
									setLevel(`Level-1`);
									reactLocalStorage.setObject("levelHeart", {
										level: `Level-1`,
									});
								}}>
								High
							</Button>
						)}
						{level === `Level-2` ? (
							<button
								disabled
								style={{ backgroundColor: "red", color: "white" }}>
								Medium
							</button>
						) : (
							<Button
								onClick={() => {
									setLevel(`Level-2`);
									reactLocalStorage.setObject("levelHeart", {
										level: `Level-2`,
									});
								}}>
								Medium
							</Button>
						)}
						{level === `Level-3` ? (
							<button
								disabled
								style={{ backgroundColor: "red", color: "white" }}>
								Low
							</button>
						) : (
							<Button
								onClick={() => {
									setLevel(`Level-3`);
									reactLocalStorage.setObject("levelHeart", {
										level: `Level-3`,
									});
								}}>
								Low
							</Button>
						)}
						{level === `N/A` ? (
							<button
								disabled
								style={{ backgroundColor: "red", color: "white" }}>
								N/A
							</button>
						) : (
							<Button
								onClick={() => {
									setLevel(`N/A`);
									reactLocalStorage.setObject("levelHeart", {
										level: `N/A`,
									});
								}}>
								N/A
							</Button>
						)}
					</ButtonGroup>
				</Box>
				<Button
					className='csvBtn button border'
					sx={{
						fontWeight: "bold",
						border: "2px solid white !important",
						backgroundColor: "transparent",
						borderRadius: "25px",
						mb: 2.5,
					}}
					variant='contained'>
					<FileDownloadIcon />
					<CSVLink
						filename={"All Heart On Heart Forms.csv"}
						data={forms}
						headers={headers}
						style={{
							textDecoration: "none",
							color: "white",
							fontWeight: "bold",
							marginLeft: 5,
						}}>
						Download Forms
					</CSVLink>
				</Button>
				<Paper
					className='container'
					sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
					<Table size='small' aria-label='a dense table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>صورة فوتوغرافية </TableCell>
								<TableCell align='left'>الإسم </TableCell>
								{/* <TableCell align='left'>الشهرة </TableCell>
								<TableCell align='left'>إسم الأب</TableCell>
								<TableCell align='left'>إسم الأم </TableCell>
								<TableCell align='left'>تاريخ الولادة</TableCell>
								<TableCell align='left'>الجنس</TableCell>
								<TableCell align='left'>القضاء</TableCell>
								<TableCell align='left'>المدينة أو القرية</TableCell>
								<TableCell align='left'>عنوان السكن بالتفصيل</TableCell>
								<TableCell align='left'>رقم الهاتف</TableCell>
								<TableCell align='left'>رقم السجل </TableCell>
								<TableCell align='left'>مكان السجل</TableCell>
								<TableCell align='left'>الوضع الاجتماعي</TableCell>
								<TableCell align='left'>عدد أفراد الأسرة </TableCell>
								<TableCell align='left'>
									تحديد الحالات المرضية الموجودة في الأسرة/ذوي احتياجات خاصة؟
								</TableCell>
								<TableCell align='left'>هل لديكم بطاقة شؤون؟</TableCell>
								<TableCell align='left'>
									هل أحد أفراد الأسرة موظف في القطاع العام/أجهزة أمنية؟
								</TableCell> */}
								<TableCell align='center'>Action</TableCell>
							</TableRow>
						</TableHead>
						{forms?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{forms.map((form) => (
									<TableRow
										className={form?._id === getId ? "selectedRow" : ""}
										key={form?._id}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										<TableCell align='left'>{count++}</TableCell>
										<TableCell align='left'>
											<img
												src={form?.imageLink || "N/A"}
												alt=''
												width='50px'
												height='50px'
												style={{ borderRadius: "50%" }}
											/>
										</TableCell>
										<TableCell align='left'>{form?.a}</TableCell>
										{/* <TableCell align='left'>{form?.b}</TableCell>
										<TableCell align='left'>{form?.c}</TableCell>
										<TableCell align='left'>{form?.d}</TableCell>
										<TableCell align='left'>{form?.e}</TableCell>
										<TableCell align='left'>{form?.f}</TableCell>
										<TableCell align='left'>{form?.g}</TableCell>
										<TableCell align='left'>{form?.h}</TableCell>
										<TableCell align='left'>{form?.i}</TableCell>
										<TableCell align='left'>{form?.j}</TableCell>
										<TableCell align='left'>{form?.k}</TableCell>
										<TableCell align='left'>{form?.l}</TableCell>
										<TableCell align='left'>{form?.m}</TableCell>
										<TableCell align='left'>{form?.n}</TableCell>
										<TableCell align='left'>{form?.o}</TableCell>
										<TableCell align='left'>{form?.p}</TableCell>
										<TableCell align='left'>{form?.q}</TableCell> */}
										<TableCell align='center'>
											<Link
												onClick={() => viewed(form?._id)}
												to={`/dashboard/form2submissions/${form?._id}`}
												style={{ textDecoration: "none" }}>
												<Button
													className='button border'
													sx={{
														fontWeight: "bold",
														border: "2px solid white !important",
														backgroundColor: "transparent",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<RemoveRedEyeIcon />
												</Button>
											</Link>

											<Button
												className='button border'
												onClick={() => handleDelete(form?._id)}
												sx={{
													fontWeight: "bold",
													border: "2px solid white !important",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									{/* 		<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell> */}
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</Grid>
		</Container>
	);
};

export default FormSubmissions2;
