import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
	Backdrop,
	Box,
	IconButton,
	Button,
	CircularProgress,
	Container,
	Grid,
} from "@mui/material";
import Swal from "sweetalert2";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";

import { styled } from "@mui/material/styles";

const ProfilePhoto = () => {
	const type = "profile";
	const [submitting, setSubmitting] = useState(false);
	const [imageLink2, setImageLink2] = useState(null);

	const Input = styled("input")({
		display: "none",
	});
	const profilephoto = { type: "profile", imageLink2 };
	const update = () => {
		setSubmitting(true);
		axios
			.put(
				`https://sawa4leb.bcard.a2hosted.com/profile/${type}`,
				profilephoto,
			)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your photo updated Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const [loading, setLoading] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "bannerAndProfile");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/dqsndznxf/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading(false);
	};
	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Update Profile Picture
			</Typography>
			<Grid
				container
				spacing={0}
				direction='column'
				alignItems='center'
				justifyContent='center'
				style={{ minHeight: "70vh" }}>
				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					sx={{ mt: 3, mb: 1, mx: "auto" }}>
					<label
						className='bgColor'
						htmlFor='icon-button-file'
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							margin: "0 9px",
							borderRadius: 5,
						}}>
						<Input
							accept='image/*'
							id='icon-button-file'
							type='file'
							onChange={uploadImage}
						/>
						<Typography
							sx={{ m: 2, color: "white" }}
							variant='h6'
							component='div'
							gutterBottom>
							Upload Your Profile Picture*
						</Typography>
						<IconButton
							color='primary'
							aria-label='upload picture'
							component='span'>
							<AttachFileIcon fontSize='large' sx={{ fontWeight: "bold" }} />
						</IconButton>
					</label>

					{loading ? (
						<Box sx={{ my: 2 }}>
							<CircularProgress className='textColor' />
						</Box>
					) : (
						<img src={imageLink2} style={{ width: "300px" }} alt='' />
					)}
				</Box>
				{imageLink2 && (
					<Button
						onClick={update}
						variant='contained'
						component='span'
						className='button border'
						sx={{
							my: 1,
							py: 0.5,
							width: "150px",
							border: "2px solid",
							backgroundColor: "transparent",
						}}>
						Update
					</Button>
				)}
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default ProfilePhoto;
