import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
	Backdrop,
	Box,
	IconButton,
	Button,
	CircularProgress,
	Container,
	Table,
	Paper,
	Grid,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material";
import Swal from "sweetalert2";

import DeleteIcon from "@mui/icons-material/Delete";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";

import { styled } from "@mui/material/styles";

const Gallery2photo = () => {
	const [submitting, setSubmitting] = useState(false);

	const [imageLink2, setImageLink2] = useState(null);
	const [deleted, setDeleted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = React.useState([]);
	useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/gallery2`)
			.then((res) => res.json())
			.then((data) => setData(data.reverse()));
	}, [submitting, deleted]);
	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(
						`https://sawa4leb.bcard.a2hosted.com/gallery2/${id}`,
					)
					.then(function (response) {
						Swal.fire(
							"Deleted!",
							"That gallery image has been deleted.",
							"success",
						);
						setDeleted(true);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "Gallery2");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/dqsndznxf/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});
	const update = () => {
		const data = { imageLink2 };
		setSubmitting(true);
		axios
			.post(`https://sawa4leb.bcard.a2hosted.com/gallery2`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your photo uploaded Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	let count = 1;
	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Update gallery Picture
			</Typography>
			<Box
				display='flex'
				flexDirection='column'
				alignItems='center'
				sx={{ mt: 3, mb: 1, mx: "auto" }}>
				<label
					className='bgColor'
					htmlFor='icon-button-file'
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						margin: "0 9px",
						borderRadius: 5,
					}}>
					<Input
						accept='image/*'
						id='icon-button-file'
						type='file'
						onChange={uploadImage}
					/>
					<Typography
						sx={{ m: 2, color: "white" }}
						variant='h6'
						component='div'
						gutterBottom>
						Upload New Gallery Picture*
					</Typography>
					<IconButton
						color='primary'
						aria-label='upload picture'
						component='span'>
						<AttachFileIcon fontSize='large' sx={{ fontWeight: "bold" }} />
					</IconButton>
				</label>

				{loading ? (
					<Box sx={{ my: 2 }}>
						<CircularProgress className='textColor' />
					</Box>
				) : (
					<img src={imageLink2} style={{ width: "300px" }} alt='' />
				)}
			</Box>
			{imageLink2 && (
				<Button
					onClick={update}
					variant='contained'
					component='span'
					className='button border'
					sx={{
						my: 1,
						py: 0.5,
						width: "150px",
						border: "2px solid",
						backgroundColor: "transparent",
					}}>
					Update
				</Button>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>

			<Grid>
				<Typography
					className='textColor'
					sx={{ my: 3, fontWeight: "bold" }}
					variant='h4'
					component='div'
					gutterBottom>
					All Photos
				</Typography>
				<Paper
					className='container'
					sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
					<Table size='small' aria-label='a dense table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>Image</TableCell>
								<TableCell align='left'>Action</TableCell>
							</TableRow>
						</TableHead>
						{data?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{data.map((image) => (
									<TableRow
										key={image?._id}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										<TableCell align='left'>{count++}</TableCell>
										<TableCell align='left'>
											<img src={image?.imageLink2} alt='' width='100px' />
										</TableCell>
										<TableCell align='left'>
											<Button
												className='button border'
												onClick={() => handleDelete(image?._id)}
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</Grid>
		</Container>
	);
};

export default Gallery2photo;
