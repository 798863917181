import React from "react";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import {
	Backdrop,
	Box,
	CircularProgress,
	Container,
	Divider,
	Grid,
	InputAdornment,
	OutlinedInput,
	TextField,
	Typography,
} from "@mui/material";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Donate = () => {
	const navigate = useNavigate();
	const [submitting, setSubmitting] = React.useState(false);
	const [amount, setAmount] = React.useState("");
	const handleAmountChange = (event) => {
		setAmount(event.target.value);
	};
	const [reason, setReason] = React.useState("");
	const handleReasonChange = (event) => {
		setReason(event.target.value);
	};
	async function handleToken(token, addresses) {
		setSubmitting(true);
		const response = await axios.post(
			"https://sawa4leb.bcard.a2hosted.com/checkout",
			{
				token,
				amount,
			},
		);
		const data = {
			cardnum: token?.card?.last4,
			name: response?.data?.details?.source?.name,
			email: response?.data?.details?.receipt_email,
			amount: response?.data?.details?.amount / 100,
			receipt: response?.data.details.receipt_url,
			reason: reason,
		};
		const { status } = response.data;
		if (status === "Success") {
			setSubmitting(false);
			setAmount("");
			setReason("");
			axios
				.post(`https://sawa4leb.bcard.a2hosted.com/donates`, data)
				.then(function (response) {
					setSubmitting(false);
					Swal.fire({
						icon: "success",
						title: `
									<h3 className='textColor' style='margin:0'>Thanks ${data?.name}!</h3>
									<P style='margin:0'>Your Donation of ${data?.amount}$ Successfully Processed</p>
									<a href=${data?.receipt}>Receipt</a>
									`,
						showConfirmButton: true,
						timer: 15000,
					}).then(function () {
						navigate("/");
					});
				})
				.catch(function (error) {
					console.log(error);
				});
		} else {
			Swal.fire({
				icon: "error",
				title: "Something went wrong",
				showConfirmButton: false,
				timer: 1500,
			});
			setSubmitting(false);
		}
	}

	return (
		<Container sx={{ pt: 3 }}>
			<Box
				className='textColor'
				display='flex'
				flexDirection='row'
				justifyContent='center'
				alignItems='center'>
				<CardGiftcardIcon fontSize='large' sx={{ mr: 1.5 }} />
				<Typography variant='h3' display='block'>
					Donate
				</Typography>
			</Box>
			<Divider className='hr' />
			<Grid
				className='color-text payment'
				container
				direction='column'
				alignItems='center'
				justifyContent='center'
				sx={{ minHeight: "70vh" }}>
				<Grid container spacing={2}>
					<Grid item md={7} sm={7} xs={12} sx={{ mx: "auto" }}>
						<Box>
							<OutlinedInput
								required
								type='number'
								sx={{ width: "100%", mb: 2 }}
								placeholder='Donation Amount'
								startAdornment={
									<InputAdornment position='start'>$</InputAdornment>
								}
								value={amount}
								onChange={handleAmountChange}
							/>
							<TextField
								required
								sx={{ width: "100%", mb: 2 }}
								label='Purpose For Donation ? / ما هو الهدف من التبرع ؟'
								multiline
								rows={4}
								value={reason}
								onChange={handleReasonChange}
							/>
							<StripeCheckout
								disabled={submitting || !amount || !reason}
								className='pay'
								style={{ width: "100%", padding: "9px 0" }}
								stripeKey={
									"pk_live_51K9aEBFRD67XdhL79hU7V9eWMVUqkgyf82KHub6A4clMkPZZXuGRWJQbADgo7utBoYSaSAaCU6qnsFXAl1EaAxtz00uLS2Xfim"
								}
								token={handleToken}
								amount={amount * 100}
								billingAddress
							/>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Donate;
