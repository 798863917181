import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link, Outlet, useLocation } from "react-router-dom";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import PortraitIcon from "@mui/icons-material/Portrait";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import FeedIcon from "@mui/icons-material/Feed";
import DialpadIcon from "@mui/icons-material/Dialpad";
import InfoIcon from "@mui/icons-material/Info";
import ReviewsIcon from "@mui/icons-material/Reviews";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import useFirebase from "../../Firebase/useFirebase";
import { Button } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import OfflineShareIcon from "@mui/icons-material/OfflineShare";

const drawerWidth = 222;

function Dashboard(props) {
	const location = useLocation();
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const drawer = (
		<Box sx={{ my: "auto" }}>
			<List>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to=''>
					<ListItem
						button
						className={location?.pathname === "/dashboard/" && "activeButton"}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<PhotoCameraBackIcon />
						</ListItemIcon>
						<ListItemText primary={"Banner Photo"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='profilephoto'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/profilephoto") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<PortraitIcon />
						</ListItemIcon>
						<ListItemText primary={"Profile Photo"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='galleryphoto'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/galleryphoto") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ViewCarouselIcon />
						</ListItemIcon>
						<ListItemText primary={"Gallery Photos"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='gallery2photo'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/gallery2photo") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ViewCarouselIcon />
						</ListItemIcon>
						<ListItemText primary={"HOH Gallery"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='colorchange'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/colorchange") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ColorLensIcon />
						</ListItemIcon>
						<ListItemText primary={"Color Change"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='headline'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/headline") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<FeedIcon />
						</ListItemIcon>
						<ListItemText primary={"Headline"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='buttonslink'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/buttonslink") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<DialpadIcon />
						</ListItemIcon>
						<ListItemText primary={"Buttons Link"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='details'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/details") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<InfoIcon />
						</ListItemIcon>
						<ListItemText primary={"Details"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='allreviews'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/allreviews") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ReviewsIcon />
						</ListItemIcon>
						<ListItemText primary={"All Reviews"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='donates'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/donates") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<MonetizationOnIcon />
						</ListItemIcon>
						<ListItemText primary={"Donations"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='allcourses'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/allcourses") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<MonetizationOnIcon />
						</ListItemIcon>
						<ListItemText primary={"Req of Course"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='formsubmissions'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/formsubmissions") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<VolunteerActivismIcon />
						</ListItemIcon>
						<ListItemText primary={"Volunteer"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='formsubmissions2'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/formsubmissions2") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<LoyaltyIcon />
						</ListItemIcon>
						<ListItemText primary={"Heart on Heart"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='allemails'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/allemails") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ContactMailIcon />
						</ListItemIcon>
						<ListItemText primary={"Contact Forms"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='mailcause'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/mailcause") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ContactMailIcon />
						</ListItemIcon>
						<ListItemText primary={"Form Dropdown"} />
					</ListItem>
				</Link>
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='almustafacontrol'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/almustafacontrol") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<OfflineShareIcon />
						</ListItemIcon>
						<ListItemText primary={"أفران المصطفى"} />
					</ListItem>
				</Link>
			</List>
		</Box>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;
	const { logOut } = useFirebase();
	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar
				position='fixed'
				className='bgColor'
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					ml: { sm: `${drawerWidth}px` },
				}}>
				<Toolbar>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						edge='start'
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: "none" } }}>
						<MenuIcon />
					</IconButton>
					<Box display='flex' sx={{ flexGrow: 1 }}>
						<DashboardIcon sx={{ mr: 1 }} />
						<Typography variant='h6'>DASHBOARD</Typography>
					</Box>
					<Button onClick={() => logOut()} color='inherit'>
						LogOut
					</Button>
				</Toolbar>
			</AppBar>
			<Box
				onClick={() => setMobileOpen(false)}
				component='nav'
				sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
				aria-label='mailbox folders'>
				<Drawer
					container={container}
					variant='temporary'
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true,
					}}
					className='dashboard'
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
						backgroundColor: "transparent",
					}}>
					{drawer}
				</Drawer>
				<Drawer
					variant='permanent'
					className='dashboard'
					sx={{
						display: { xs: "none", sm: "block" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
						backgroundColor: "transparent",
					}}
					open>
					{drawer}
				</Drawer>
			</Box>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}>
				<Toolbar />
				<Outlet></Outlet>
			</Box>
		</Box>
	);
}

Dashboard.propTypes = {
	window: PropTypes.func,
};

export default Dashboard;
