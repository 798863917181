const firebaseConfig = {
	apiKey: "AIzaSyBGaTAcqeNDlLfUzma7BuOc_7kanUAgRhA",
	authDomain: "sawa4leb.firebaseapp.com",
	projectId: "sawa4leb",
	storageBucket: "sawa4leb.appspot.com",
	messagingSenderId: "559690905768",
	appId: "1:559690905768:web:312489e700a7d6ce84aa9d",
	measurementId: "G-4QE0QTWF8H",
};
export default firebaseConfig;
