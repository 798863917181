import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Backdrop, Button, CircularProgress, Container } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";

const Details = () => {
	const form = useRef();
	const [submitting, setSubmitting] = useState(false);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			details: "",
			video: "",
		},
	});
	const type = "details";
	useEffect(() => {
		axios
			.get(`https://sawa4leb.bcard.a2hosted.com/details/${type}`)
			.then((res) => {
				reset(res.data);
			});
	}, [reset]);
	const onSubmit = ({ details, video }) => {
		setSubmitting(true);
		const data = { details, video, type: "details" };
		axios
			.put(
				`https://sawa4leb.bcard.a2hosted.com/details/${type}`,
				data,
			)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your details Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Details
			</Typography>

			<form ref={form} onSubmit={handleSubmit(onSubmit)} method='post'>
				<Grid container spacing={3}>
					<Grid item md={12} sm={12} xs={12}>
						<TextField
							required
							name='Details'
							label='Details'
							fullWidth
							multiline
							rows={5}
							InputLabelProps={{
								shrink: true,
							}}
							{...register("details", { required: true })}
						/>
					</Grid>
					<Grid item md={12} sm={12} xs={12}>
						<TextField
							required
							name='Video Link'
							label='Video Link'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("video", { required: true })}
						/>
					</Grid>

					<Grid item md={12} sm={12} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Details;
