import React from "react";
import Typography from "@mui/material/Typography";
import { Container, Grid } from "@mui/material";

import Image from "./Image";
import Video from "./Video";
import Details from "./Details";

const AlMustafaControl = () => {
	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Update أفران المصطفى Page
			</Typography>
			<Grid container spacing={4} sx={{ my: 2 }}>
				<Grid item md={4} xs={12} sx={{ mx: "auto" }}>
					<Image />
				</Grid>
				<Grid item md={4} xs={12} sx={{ mx: "auto" }}>
					<Video />
				</Grid>
				<Grid item md={4} xs={12} sx={{ mx: "auto" }}>
					<Details />
				</Grid>
			</Grid>
		</Container>
	);
};

export default AlMustafaControl;
