import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	TextField,
} from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
import { useForm } from "react-hook-form";

const Details = () => {
	const [submitting, setSubmitting] = useState(false);
	const form = useRef();
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			details: "",
		},
	});
	const type = "alMustafaCollectionDetails";
	useEffect(() => {
		axios
			.get(
				`https://sawa4leb.bcard.a2hosted.com/alMustafaCollectionDetails/${type}`,
			)
			.then((res) => {
				reset(res.data);
			});
	}, [reset]);
	const onSubmit = ({ details }) => {
		setSubmitting(true);
		const data = { details, type: "alMustafaCollectionDetails" };
		axios
			.put(
				`https://sawa4leb.bcard.a2hosted.com/alMustafaCollectionDetails/${type}`,
				data,
			)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your details Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<div>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Details
			</Typography>
			<Box
				display='flex'
				flexDirection='column'
				alignItems='center'
				sx={{ mt: 3, mb: 1, mx: "auto" }}>
				<form ref={form} onSubmit={handleSubmit(onSubmit)} method='post'>
					<Grid container spacing={3}>
						<Grid item md={12} sm={12} xs={12}>
							<TextField
								required
								name='Details'
								label='Details'
								fullWidth
								multiline
								rows={9}
								InputLabelProps={{
									shrink: true,
								}}
								{...register("details", { required: true })}
							/>
						</Grid>
						<Grid item md={12} sm={12} xs={12}>
							<Button
								type='submit'
								variant='contained'
								sx={{
									width: "100%",
									mb: 2,
									px: 3,
									fontWeight: "bold",
									borderRadius: "25px",
								}}>
								Save
							</Button>
						</Grid>
					</Grid>
				</form>
			</Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default Details;
