import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
	Backdrop,
	Box,
	IconButton,
	Button,
	CircularProgress,
	Container,
	Divider,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";

import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const Form = () => {
	const navigate = useNavigate();
	const form = useRef();
	const [submitting, setSubmitting] = useState(false);

	const { register, handleSubmit, reset } = useForm();
	const onSubmit = ({
		fullname,
		date,
		phone,
		address,
		educationLevel,
		birthdate,
		gender,
		occupation,
		skills,
		drawAttention,
		activities,
		doneVolunteerbefore,
		volunteerTime,
		reasonForVolunteer,
		lighting,
	}) => {
		setSubmitting(true);
		const infos = {
			fullname,
			date,
			phone,
			address,
			educationLevel,
			birthdate,
			gender,
			occupation,
			skills,
			drawAttention,
			activities,
			doneVolunteerbefore,
			volunteerTime,
			reasonForVolunteer,
			lighting,
			imageLink: imageLink2,
			importance: "N/A",
		};
		emailjs
			.sendForm(
				"service_bp2h35d",
				"template_ggd2f99",
				form.current,
				"user_i5224CgRgcclb82ilRvnO",
			)
			.then(
				(result) => {
					axios
						.post(
							`https://sawa4leb.bcard.a2hosted.com/forms`,
							infos,
						)
						.then(function (response) {
							setSubmitting(false);
							Swal.fire({
								icon: "success",
								title: "شكرا لك وسيتم إعادة توجيهك إلى الصفحة الرئيسية",
								showConfirmButton: true,
								timer: 2500,
							}).then(function () {
								reset();
								navigate("/");
							});
						})
						.catch(function (error) {
							console.log(error);
						});
				},
				(error) => {
					console.log(error.text);
				},
			);
	};
	const [imageLink2, setImageLink2] = useState(null);

	const [loading, setLoading] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "evahassoun");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/dqsndznxf/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});
	return (
		<Container sx={{ pt: 1 }} dir='rtl'>
			<Typography
				variant='h3'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				جمعية المرأة في السفيرة
				{/*  Women's Association in Al-Safira
				 */}
				<Divider className='hr' />
			</Typography>
			<Typography variant='h4' gutterBottom className='textColor'>
				إستمارة تطوع {/*  Volunteer Form */}
			</Typography>
			<Grid
				container
				spacing={0}
				direction='column'
				alignItems='center'
				justifyContent='center'
				style={{ minHeight: "70vh" }}>
				<Box>
					<form ref={form} onSubmit={handleSubmit(onSubmit)} method='post'>
						<Box
							display='flex'
							flexDirection='column'
							alignItems='center'
							sx={{ mt: 3, mb: 1, mx: "auto" }}>
							<label
								htmlFor='icon-button-file'
								className='bgColor'
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									margin: "0 9px",
									borderRadius: 5,
								}}>
								<Input
									accept='image/*'
									id='icon-button-file'
									type='file'
									onChange={uploadImage}
								/>
								<Typography
									sx={{ m: 2, color: "white" }}
									variant='h6'
									component='div'
									gutterBottom>
									الخطوة الأولى : إرفاق صورة إخراج القيد هنا أولاً *
								</Typography>
								<IconButton
									color='primary'
									aria-label='upload picture'
									component='span'>
									<AttachFileIcon
										fontSize='large'
										sx={{ fontWeight: "bold" }}
									/>
								</IconButton>
							</label>

							{loading ? (
								<Box sx={{ my: 2 }}>
									<CircularProgress className='textColor' />
								</Box>
							) : (
								<img src={imageLink2} style={{ width: "300px" }} alt='' />
							)}
						</Box>
						<TextField
							sx={{ display: "none" }}
							value={imageLink2}
							variant='standard'
							{...register("imageLink")}
						/>
						{imageLink2 && (
							<Grid container spacing={3}>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='Fullname'
										label='الإسم الثلاثي'
										fullWidth
										variant='standard'
										{...register("fullname", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='Date'
										label=' التاريخ'
										fullWidth
										variant='standard'
										{...register("date", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='Phone'
										label='التلفون'
										fullWidth
										variant='standard'
										{...register("phone", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='Address'
										label='العنوان'
										fullWidth
										variant='standard'
										{...register("address", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='Education Level'
										label='المستوى التعليمي'
										fullWidth
										variant='standard'
										{...register("educationLevel", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='Birthdate'
										label='تاريخ الولادة/العمر'
										fullWidth
										variant='standard'
										{...register("birthdate", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='Gender'
										label='ذكر/أنثى'
										fullWidth
										variant='standard'
										{...register("gender", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='Occupation'
										label='المهنة'
										fullWidth
										variant='standard'
										{...register("occupation", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='The most important personal skills and abilities of the volunteer'
										label='أهم المهارات و القدرات الشخصية لدى المتطوع'
										fullWidth
										variant='standard'
										{...register("skills", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='Draw attention to the association and its goals'
										label='اوجه الاهتمام بالجمعية و أهدافها'
										fullWidth
										variant='standard'
										{...register("drawAttention", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='The most important activities of the association that you would like to contribute to'
										label='أهم أنشطة الجمعية التي تود المساهمة فيها'
										fullWidth
										variant='standard'
										{...register("activities", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='Have you done volunteer work before/what is it?'
										label='هل قمت بأعمال تطوعية من قبل/ و ما هو؟'
										fullWidth
										variant='standard'
										{...register("doneVolunteerbefore", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='How much time do you have to ?'
										label='ما هو الوقت المتاح لديك للتطوع به'
										fullWidth
										variant='standard'
										{...register("volunteerTime", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='The reason to volunteer'
										label='سبب التطوع'
										fullWidth
										variant='standard'
										{...register("reasonForVolunteer", { required: true })}
									/>
								</Grid>
								<Grid item md={6} sm={6} xs={12}>
									<TextField
										required
										name='Lighting'
										label='الامضاء'
										fullWidth
										variant='standard'
										{...register("lighting", { required: true })}
									/>
								</Grid>
								<Grid item md={12} sm={12} xs={12}>
									<Typography
										variant='h6'
										className='textColor'
										sx={{ fontWeight: "bold" }}>
										تذكير بإرسال الطلب مع اخراج قيد فردي و شكرا
									</Typography>
								</Grid>
								<Grid item md={12} sm={12} xs={12}>
									<Button
										type='submit'
										variant='contained'
										sx={{
											width: "100%",
											mb: 2,
											px: 3,
											fontWeight: "bold",
											borderRadius: "25px",
										}}>
										إرسال الطلب
									</Button>
								</Grid>
							</Grid>
						)}
					</form>{" "}
				</Box>
			</Grid>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Form;
