import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Backdrop, Button, CircularProgress, Container } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";

const ColorChange = () => {
	const [submitting, setSubmitting] = useState(false);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			color: "",
		},
	});
	const type = "color";
	useEffect(() => {
		axios
			.get(`https://sawa4leb.bcard.a2hosted.com/color/${type}`)
			.then((res) => {
				reset(res.data);
			});
	}, [reset]);
	const onSubmit = ({ color }) => {
		setSubmitting(true);
		const data = {
			type: "color",
			color,
		};
		axios
			.put(
				`https://sawa4leb.bcard.a2hosted.com/color/${type}`,
				data,
			)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Your theme color changed Successfully",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 1 }}>
			<Typography
				variant='h4'
				gutterBottom
				className='textColor'
				sx={{ fontWeight: "bold" }}>
				Change Color
			</Typography>

			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					spacing={3}
					display='flex'
					flexDirection='column'
					alignContent='center'
					sx={{ mt: 5 }}>
					<Grid item md={6} sm={6} xs={12}>
						<TextField
							required
							name='color'
							label='color'
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							{...register("color", { required: true })}
						/>
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Button
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default ColorChange;
