import { Container, Divider, Typography } from "@mui/material";
import React, { useEffect } from "react";
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./Gallery.css";
SwiperCore.use([EffectCoverflow, Pagination]);

const Gallery = () => {
	const [data, setData] = React.useState([]);
	useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/gallery`)
			.then((res) => res.json())
			.then((data) => setData(data));
	}, []);
	const [headline, setHeadline] = React.useState([]);
	React.useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/headline/headline`)
			.then((res) => res.json())
			.then((data) => setHeadline(data));
	}, []);
	return (
		<Container sx={{ pt: 5 }}>
			<Typography
				className='textColor'
				sx={{ mb: 2, fontWeight: 900 }}
				variant='h4'
				component='div'
				gutterBottom>
				{headline?.gallery}
				<Divider className='hr' />
			</Typography>

			<Swiper
				effect={"coverflow"}
				grabCursor={true}
				centeredSlides={true}
				slidesPerView={"auto"}
				initialSlide={1}
				coverflowEffect={{
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier: 1,
					slideShadows: false,
				}}
				pagination={true}
				className='mySwiper'>
				{data?.map((a) => (
					<SwiperSlide key={a?._id}>
						<img src={a?.imageLink2} alt='' />
					</SwiperSlide>
				))}
			</Swiper>
		</Container>
	);
};

export default Gallery;
