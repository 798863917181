import {
	Button,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const AllMails = () => {
	const getId = reactLocalStorage.getObject("selectedRowMail")?.selected;
	const viewed = (id) => {
		reactLocalStorage.setObject("selectedRowMail", { selected: id });
	};
	const [deleted, setDeleted] = useState(false);
	const [mails, setMails] = useState([]);
	useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/emails`)
			.then((res) => res.json())
			.then((data) => setMails(data.reverse()));
	}, [deleted]);
	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(
						`https://sawa4leb.bcard.a2hosted.com/emails/${id}`,
					)
					.then(function (response) {
						Swal.fire("Deleted!", "That mail has been deleted.", "success");
						setDeleted(true);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	let count = 1;

	const headers = [
		{ label: "ID", key: "_id" },
		{ label: "User Name", key: "userName" },
		{ label: "User Email", key: "userEmail" },
		{ label: "Subject", key: "subject" },
		{ label: "Phone Number", key: "phoneNumber" },
		{ label: "Causes", key: "causes" },
		{ label: "Message", key: "message" },
		{ label: "Image", key: "imageLink" },
	];

	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }}>
			<Grid>
				<Typography
					className='textColor'
					sx={{ mb: 3, fontWeight: "bold" }}
					variant='h4'
					component='div'
					gutterBottom>
					All Mails
				</Typography>
				<Button
					className='csvBtn button border'
					sx={{
						fontWeight: "bold",
						border: "2px solid white !important",
						backgroundColor: "transparent",
						borderRadius: "25px",
						mb: 2.5,
					}}
					variant='contained'>
					<FileDownloadIcon />
					<CSVLink
						filename={"All Mails.csv"}
						data={mails}
						headers={headers}
						style={{
							textDecoration: "none",
							color: "white",
							fontWeight: "bold",
							marginLeft: 5,
						}}>
						Download Forms
					</CSVLink>
				</Button>
				<Paper
					className='container'
					sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
					<Table size='small' aria-label='a dense table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>Photo</TableCell>
								<TableCell align='left'>Name</TableCell>
								<TableCell align='left'>Cause</TableCell>
								{/* <TableCell align='left'>Email</TableCell>
								<TableCell align='left'>Subject</TableCell>
								<TableCell align='left'>Details</TableCell> */}
								<TableCell align='center'>Action</TableCell>
							</TableRow>
						</TableHead>
						{mails?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{mails.map((mail) => (
									<TableRow
										className={mail?._id === getId ? "selectedRow" : ""}
										key={mail?._id}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										<TableCell align='left'>{count++}</TableCell>
										<TableCell align='left'>
											<img
												src={mail?.imageLink || "N/A"}
												alt=''
												width='50px'
												height='50px'
												style={{ borderRadius: "50%" }}
											/>
										</TableCell>
										<TableCell align='left'>{mail?.userName}</TableCell>
										<TableCell align='left'>{mail?.causes || "N/A"}</TableCell>
										{/* <TableCell align='left'>
											{mail?.userEmail || "N/A"}
										</TableCell>
										<TableCell align='left'>{mail?.subject}</TableCell>
										<TableCell align='left'>{mail?.message}</TableCell> */}
										<TableCell align='center'>
											<Link
												onClick={() => viewed(mail?._id)}
												to={`/dashboard/mails/${mail?._id}`}
												style={{ textDecoration: "none" }}>
												<Button
													className='button border'
													sx={{
														fontWeight: "bold",
														border: "2px solid white !important",
														backgroundColor: "transparent",
														borderRadius: "25px",
														m: 0.5,
													}}
													variant='contained'>
													<RemoveRedEyeIcon />
												</Button>
											</Link>

											<Button
												className='button border'
												onClick={() => handleDelete(mail?._id)}
												sx={{
													fontWeight: "bold",
													border: "2px solid white !important",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									{/* <TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell> */}
									<TableCell align='left'>N/A</TableCell>
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</Grid>
		</Container>
	);
};

export default AllMails;
