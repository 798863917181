import { CardMedia } from "@mui/material";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
/* import HashLoader from "react-spinners/HashLoader"; */
import useFirebase from "../Firebase/useFirebase";

const AdminRoute = ({ children }) => {
	const { user, isLoading } = useFirebase();
	const location = useLocation();
	if (isLoading) {
		return (
			<>
				<div
					style={{
						position: "absolute",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					{/* <HashLoader size={100} color={"red"} /> */}
					<CardMedia
						image='/loading.png'
						alt='loading logo'
						className='loading-logo'
						sx={{
							width: 170,
							height: 170,
						}}
					/>
				</div>
			</>
		);
	}
	if (user?.email) {
		return children;
	}
	return <Navigate to='/login' state={{ from: location }} />;
};

export default AdminRoute;
