import { Box, Container, Fab, Grid, Typography } from "@mui/material";
import React from "react";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { Link } from "react-router-dom";
import TouchAppIcon from "@mui/icons-material/TouchApp";

const Footer = () => {
	const [headline, setHeadline] = React.useState([]);
	React.useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/headline/headline`)
			.then((res) => res.json())
			.then((data) => setHeadline(data));
	}, []);
	return (
		<Container>
			<Box sx={{ mt: 5, mb: 2, display: "flex", justifyContent: "center" }}>
				<Grid container spacing={5}>
					<Grid item md={6} xs={12}>
						<Box
							sx={{ border: "2px solid", borderRadius: "10px" }}
							className='borderColor'>
							<Box sx={{ mt: -3 }}>
								<Link to='/form' style={{ textDecoration: "none" }}>
									<Fab
										className='pulse'
										variant='extended'
										sx={{
											px: 4,
											mx: 0.5,
											fontWeight: "bold",
										}}>
										<VolunteerActivismIcon sx={{ mr: 1.5 }} />
										للتطوع معنا
									</Fab>
								</Link>
							</Box>
							<Typography
								variant='body2'
								gutterBottom
								className='textColor'
								sx={{ fontWeight: "bold", my: 2, px: 0.5 }}>
								العمل التطوعي هو حاجة إجتماعية تحقق الترابط، التآلف و التآخي بين
								أفرد المجتمع. من فوائده: زيادة الثقة بالنفس، تعزيز العلاقات
								الإجتماعية، تطوير المهارات القيادية، التخلص من المشاعر السلبية،
								ويمنحك الاحساس بالفخر لمساعدة الغير وتغيير المجتمع نحو الأفضل :
								الشروط أن يكون يحب العمل الإجتماعي التطوعي بعيدا عن أي مصالح
								شخصية و الا بيكون عم يختار الجهة الخاطئة العمر: ١٦ سنة و ما فوق
								ان يكون من السفيرة حاليا
							</Typography>
						</Box>
					</Grid>
					<Grid item md={6} xs={12}>
						<Box
							sx={{ border: "2px solid", borderRadius: "10px" }}
							className='borderColor'>
							<Box sx={{ mt: -3 }}>
								<Link to='/heartonheart' style={{ textDecoration: "none" }}>
									<Fab
										className='pulse'
										variant='extended'
										sx={{
											px: 4,
											mx: 0.5,
											fontWeight: "bold",
										}}>
										<VolunteerActivismIcon sx={{ mr: 1.5 }} />
										قلب على قلب
									</Fab>
								</Link>
							</Box>
							<Typography
								variant='body2'
								gutterBottom
								className='textColor'
								sx={{ fontWeight: "bold", my: 2, px: 0.5 }}>
								مشروع القلب عالقلب: هو بطاقة تعطى لكل عائلةمحتاجة لمدة ٣ شهور
								حيث تتمكن العائلة باختيار احتياجاتها مرة كل شهر حسب النقط
								المحددة لها من محل مخصص للجمعية لإستلام الحصص عبر النقاط
								العائلات المكفولة : ١٣٥ عائلة تتغير حسب الحاجة كل ٣ شهور.
								المطلوب اخراج قيد عائلي لتعبئة الاستمارة. أن يكون المسجل لا يحمل
								بطاقة الشؤون ملاحظة : لو تسجلتو ممكن تاخدو البطاقة هالشهر او
								خلال ٣ شهور و ذلك لكثرة المسجلين عم نحاول قد ما فينا نوصل للجميع
								و لكن عندنا عدد محدد. اذا حدا بحب يساعد لنزيد عدد عيل ساعتها
								منزيد.
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Box>
			{headline?.student === "on" && (
				<Link to='course' style={{ textDecoration: "none" }}>
					<Box
						className='bgColor'
						sx={{
							width: "100%",
							py: 3,
							mt: 5,
							color: "white",
							fontWeight: "bold",
							borderRadius: "10px",
						}}>
						<Typography
							variant='body'
							sx={{ color: "white", fontWeight: "bold" }}>
							<TouchAppIcon sx={{ mr: 1 }} /> طلب  تسجيل  طالب في الدورة العلمية
							<TouchAppIcon sx={{ ml: 1 }} />
						</Typography>
					</Box>
				</Link>
			)}
		</Container>
	);
};

export default Footer;
