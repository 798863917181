import { Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import ReactPlayer from "react-player";

const About = () => {
	const [data, setData] = React.useState([]);
	React.useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/details/details`)
			.then((res) => res.json())
			.then((data) => setData(data));
	}, []);
	const [headline, setHeadline] = React.useState([]);
	React.useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/headline/headline`)
			.then((res) => res.json())
			.then((data) => setHeadline(data));
	}, []);
	return (
		<Container sx={{ pt: 5 }}>
			<Typography
				className='textColor'
				sx={{ mb: 2, fontWeight: 900 }}
				variant='h4'
				component='div'
				gutterBottom>
				{headline?.aboutUs}
				<Divider className='hr' />
			</Typography>

			<Grid container spacing={2}>
				<Grid item md={9} className='textColor' sx={{ mx: "auto" }}>
					<Typography variant='bod1' gutterBottom>
						{data?.details}
					</Typography>
				</Grid>

				<Grid item md={7.5} xs={12} sx={{ mx: "auto" }}>
					<ReactPlayer width='100%' height='100%' url={data?.video} controls />
				</Grid>
			</Grid>
		</Container>
	);
};

export default About;
