import {
	Button,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";

const Donations = () => {
	const [donates, setDonates] = useState([]);
	const [deleted, setDeleted] = useState(false);
	useEffect(() => {
		fetch(`https://sawa4leb.bcard.a2hosted.com/donates`)
			.then((res) => res.json())
			.then((data) => setDonates(data.reverse()));
	}, [deleted]);

	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(
						`https://sawa4leb.bcard.a2hosted.com/donates/${id}`,
					)
					.then(function (response) {
						Swal.fire(
							"Deleted!",
							"That Donate info has been deleted.",
							"success",
						);
						setDeleted(true);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	let count = 1;
	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }}>
			<Grid>
				<Typography
					className='textColor'
					sx={{ mb: 3, fontWeight: "bold" }}
					variant='h4'
					component='div'
					gutterBottom>
					All Donations
				</Typography>
				<Grid item xs={12} md={12}>
					<Paper
						className='container'
						sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
						<Table size='small' aria-label='a dense table'>
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>No</TableCell>
									<TableCell align='left'>Name</TableCell>
									<TableCell align='left'>Email</TableCell>
									<TableCell align='left'>Card</TableCell>
									<TableCell align='left'>Amount</TableCell>
									<TableCell align='left'>Receipt</TableCell>
									<TableCell align='left'>Reason</TableCell>
									<TableCell align='left'>Action</TableCell>
								</TableRow>
							</TableHead>
							{donates?.length > 0 ? (
								<TableBody sx={{ td: { py: 1 } }}>
									{donates.map((donate) => (
										<TableRow
											key={donate?._id}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}>
											<TableCell align='left'>{count++}</TableCell>
											<TableCell align='left'>
												{donate?.name || "N/A"}
											</TableCell>
											<TableCell align='left'>
												{donate?.email || "N/A"}
											</TableCell>
											<TableCell align='left'>
												{donate?.cardnum || "N/A"}
											</TableCell>
											<TableCell align='left'>
												{donate?.amount || "N/A"} $
											</TableCell>
											<TableCell align='left'>
												<a
													href={`${donate?.receipt}`}
													target='_blank'
													rel='noreferrer'>
													Receipt
												</a>
											</TableCell>
											<TableCell align='left'>
												{donate?.reason || "N/A"}
											</TableCell>

											<TableCell align='left'>
												<Button
													className='button border'
													onClick={() => handleDelete(donate?._id)}
													sx={{
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
													}}
													variant='contained'>
													<DeleteIcon />
												</Button>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							) : (
								<TableHead sx={{ th: { fontWeight: "bold" } }}>
									<TableRow>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
									</TableRow>
								</TableHead>
							)}
						</Table>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Donations;
