import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
	Button,
	Table,
	Paper,
	Grid,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TextField,
	Box,
} from "@mui/material";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useForm } from "react-hook-form";

const Video = () => {
	const [submitting, setSubmitting] = useState(false);
	const [deleted, setDeleted] = useState(false);
	const { register, handleSubmit, reset } = useForm();
	let count = 1;
	const [videos, setVideos] = useState();
	React.useEffect(() => {
		fetch(
			`https://sawa4leb.bcard.a2hosted.com/alMustafaCollectionVideos`,
		)
			.then((res) => res.json())
			.then((data) => setVideos(data.reverse()));
	}, [submitting, deleted]);
	const onSubmit2 = ({ videoTitle, videoLink }) => {
		const data = {
			videoTitle,
			videoLink,
		};
		axios
			.post(
				`https://sawa4leb.bcard.a2hosted.com/alMustafaCollectionVideos`,
				data,
			)
			.then(function (response) {
				setSubmitting(true);
				Swal.fire({
					icon: "success",
					title: "Successfully Added",
					showConfirmButton: true,
					timer: 2500,
				});
				reset();
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const handleDelete2 = (id) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.delete(
						`https://sawa4leb.bcard.a2hosted.com/alMustafaCollectionVideos/${id}`,
					)
					.then(function (response) {
						setDeleted(false);
						Swal.fire("Deleted!", "That Video has been deleted.", "success");
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	return (
		<div>
			<Typography
				className='textColor'
				sx={{ mb: 3, fontWeight: "bold" }}
				variant='h4'
				component='div'
				gutterBottom>
				Post New Video
			</Typography>
			<Box
				display='flex'
				flexDirection='column'
				alignItems='center'
				sx={{ mt: 3, mb: 1, mx: "auto" }}>
				<form onSubmit={handleSubmit(onSubmit2)} method='post'>
					<Grid container spacing={2}>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<TextField
								sx={{ width: "100%" }}
								id='outlined-basic'
								name='videoLink'
								label='Video Link'
								{...register("videoLink", { required: true })}
							/>
						</Grid>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<TextField
								sx={{ width: "100%" }}
								id='outlined-basic'
								name='videoTitle'
								label='Video Title'
								{...register("videoTitle", { required: true })}
							/>
						</Grid>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<Button
								className='sendButton'
								type='submit'
								variant='contained'
								sx={{
									width: "100%",
									mb: 2,
									px: 3,
									fontWeight: "bold",
									borderRadius: "25px",
								}}>
								Post
							</Button>
						</Grid>
					</Grid>
				</form>
				<Paper
					className='container'
					sx={{
						overflow: "auto",
						maxHeight: "80vh",
						maxWidth: "90vw",
						width: "100%",
						pt: 2,
					}}>
					<Table size='small' aria-label='a dense table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>Title</TableCell>
								<TableCell align='left'>Action</TableCell>
							</TableRow>
						</TableHead>
						{videos?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{videos.map((video) => (
									<TableRow
										key={video?._id}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										<TableCell align='left'>{count++}</TableCell>
										<TableCell align='left'>{video?.videoTitle}</TableCell>
										<TableCell align='left'>
											<Button
												className='button border'
												onClick={() => handleDelete2(video?._id)}
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</Box>
		</div>
	);
};

export default Video;
